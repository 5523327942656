import React from "react";
import Modal from 'react-bootstrap/Modal';
import {
    Row,
    Button
} from "react-bootstrap";
import SimpleLineChart from "components/Charts/SimpleLineChart";

function PositionChartModal({modalShow, data, position, cancel}) {


    return (
        <Modal show={modalShow} onHide={() => cancel()}>
            <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        <b>Position {position.securityName} history for current month</b>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <SimpleLineChart key={position.securityName} title={position.securityName} data={data} yDataKey="avgVal" xDataKey="dayNumber"></SimpleLineChart>
                    </Row>
                    
                </Modal.Body>
        </Modal>
    )

}

export default PositionChartModal;