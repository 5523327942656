import React from "react";
import { Button } from "react-bootstrap";

function PortfolioAlertListRow({portfolioAlert, onEdit, onDelete}) {

    const editPortfolioAlert = () => {

        onEdit(portfolioAlert);

    }

    const deletePortfolioAlert = () => {

        onDelete(portfolioAlert);

    }

    return(
        <tr>
            <td>{portfolioAlert.portfolioName}</td>
            <td>{portfolioAlert.minimumProfit}</td>
            <td>{portfolioAlert.minimumValue}</td>
            <td><Button variant="link" onClick={editPortfolioAlert}>Edit</Button></td>
            <td><Button variant="danger" onClick={deletePortfolioAlert}>Remove</Button></td>
        </tr>
    )

}

export default PortfolioAlertListRow;