import React from "react";
import {
  Button,

} from "react-bootstrap";
import Moment from 'react-moment';

function SecurityTradeRow({trade,onRowSelected, onRowDelete}) {

    const rowSelected = (event) => {
      
        onRowSelected(trade);
  
      }

      const onTradeDelete = (event) => {
        onRowDelete(trade);
      }

      const checkTradeType = (trade) =>  {

        if(trade.isBuy) {
            return "BUY";
        }else {
            return "SELL";
        }

      }

      return(
        <tr>
        <td>{trade.securityName}</td>
        <td>{trade.numberOfSecuritiesBoughtOrSold}</td>
        <td>
            <Moment format="DD.MM.YYYY hh:mm">
            {trade.transactionDate}
            </Moment>
        </td>
        <td>{trade.securityPrice}</td>
        <td>{checkTradeType(trade)}</td>
        <td><Button variant="link" onClick={rowSelected}>Show</Button></td>
        <td><Button variant="danger" onClick={onTradeDelete}>Remove</Button></td>
      </tr>
      )

}

export default SecurityTradeRow;