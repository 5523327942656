import React,{useState, useContext} from "react";
import PriceAlertForm from "../components/PriceAlerts/PriceAlertForm";
import PriceAlertList from "../components/PriceAlerts/PriceAlertList";
import NotificationAlert from "react-notification-alert";
import { config } from '../config';
import {getRandomNumber} from '../components/utils';
import { useHistory } from "react-router-dom";
import {AuthContext} from '../authContext';
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import axios from "axios";

function PriceAlerts() {

  const [refreshVar, refreshTable] = useState({});
  const [selectedAlert, setSelectedAlert] = useState({
    lowSecurityPrice:0.0,
      highSecurityPrice: 0.0,
      positionProfitOrLossMin: 0.0,
      securityName:undefined,
      id: undefined
  });
  const notificationAlertRef = React.useRef(null);

  const ctx = useContext(AuthContext);
  const history = useHistory();
  let tokenStr;
  
  if (ctx && ctx.token && ctx.token.idToken) {
    tokenStr = ctx.token.idToken.rawIdToken;
  } else {
    history.push('/');
  }

  const showNotification = (message, type) => {

    let options = {};
    options = {
        place: 'tc',
        message: (
            <div>
                <div>
                    {message}
                </div>
            </div>
        ),
        type: type,
        icon: "nc-icon nc-bell-55",
        autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);

}

  const onAlertDelete = (alert) => {
    console.log('Delete confirmed : ', alert)
    const result = axios({
      method: 'DELETE',
      url: config.backendUrl + '/alert/'+alert.id,
      headers: {"Authorization" : `Bearer ${tokenStr}`}
    })

    result.then(() => {
      const randomNum = getRandomNumber(1000);
      refreshTable(randomNum);
      showNotification('Alert removed', 'info');
    })

  }

  const alertEdit = (alert) => {
    console.log('Alert selected: ',alert);
    setSelectedAlert(alert);
  }

  const cancelEdit = () => {
    const emptyAlert = {
      lowSecurityPrice:0.0,
      highSecurityPrice: 0.0,
      positionProfitOrLossMin: 0.0,
      securityName:undefined,
      id: undefined
    }
    console.log('Cancel edit : ', emptyAlert);
    setSelectedAlert(emptyAlert);
  }

  return (
    <>
     <div className="rna-container">
                <NotificationAlert ref={notificationAlertRef} />
    </div>
      <Container fluid>
        <PriceAlertForm refreshTable={refreshTable} selectedAlertParam={selectedAlert} onCancel={cancelEdit}></PriceAlertForm>
        <PriceAlertList needRefresh={refreshVar} onAlertDelete={onAlertDelete} onEdit={alertEdit}></PriceAlertList>
      </Container>
    </>
  );
}

export default PriceAlerts;
