import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from '../authContext';
import {
    Button,
    Row,
    Col,
    Container,
} from "react-bootstrap";
import {
    listNotes, saveNote, removeNote, saveAttachment, listNoteAttachments,
    removeAttachment
} from '../services/httpService';
import NotificationAlert from "react-notification-alert";
import NotesList from '../components/Notes/NotesList';
import NoteForm from '../components/Notes/NoteForm';

function NotesView() {

    const [notes, setNotes] = useState([]);
    const [attachments, setAttachments] = useState([]);
    const [selectedNote, setSelectedNote] = useState({});
    const [showForm, setShowForm] = useState(false);
    const notificationAlertRef = React.useRef(null);
    const history = useHistory();
    const ctx = useContext(AuthContext);

    let tokenStr;


    if (ctx && ctx.token && ctx.token.accessToken) {
        tokenStr = ctx.token.accessToken;
    } else {
        history.push('/');
    }

    const emptyNote = ({
        securityName: '',
        securityNotes: '',
        highSecurityAlertPrice: 0,
        lowSecurityAlertPrice: 0
    })

    useEffect(async () => {
        setSelectedNote(emptyNote);
        await retrieveNotes();
    }, [])

    const showNotification = (message, type) => {

        let options = {};
        options = {
            place: 'tc',
            message: (
                <div>
                    <div>
                        {message}
                    </div>
                </div>
            ),
            type: type,
            icon: "nc-icon nc-bell-55",
            autoDismiss: 7,
        };
        notificationAlertRef.current.notificationAlert(options);

    }

    const retrieveNotes = async () => {

        const result = await listNotes(tokenStr);
        console.log('Notes result : ', result);
        setNotes(result.data);

    }

    const showEdit = async () => {

        setShowForm(true);

    }

    const retrieveNoteAttachments = async (note) => {

        if (note && note.id) {
            const result = await listNoteAttachments(note.id, tokenStr);
            if (result.status === 200) {
                console.log('Attachments : ', result.data);
                setAttachments(result.data);
            }
        }

    }

    const cancelEdit = async () => {

        setSelectedNote(emptyNote);
        setShowForm(false);

    }

    const saveNoteFunc = async (note) => {


        const result = await saveNote(note, tokenStr);
        if (result.status === 200) {
            setSelectedNote(emptyNote);
            console.log('Save note result : ', result);
            setShowForm(false);
            showNotification('Note saved', 'info');
            await retrieveNotes();
        }


    }

    const removeNoteFunc = async (note) => {

        if (note.id) {
            const result = await removeNote(note.id, tokenStr);
            if (result.status === 200) {
                await retrieveNotes();
                showNotification('Note removed', 'info');
            }

        }

    }

    const editNote = async (note) => {

        if (note && note.id) {

            setShowForm(true);
            setSelectedNote(note);
            await retrieveNoteAttachments(note);
        }

    }

    const updloadFile = async (formData, note) => {
        console.log('Note : ', note)
        const result = await saveAttachment(formData, note.id, tokenStr);

        console.log('File upload result : ', result);
        if (result.status > 199 && result.status < 300) {
            showNotification('Attachment uploaded', 'info');
            await retrieveNoteAttachments(selectedNote);
        }
    }

    const removeAttachmentFunc = async (attachment) => {

        console.log('Removing attachment : ', attachment);
        if (attachment && attachment.id) {
            const result = await removeAttachment(attachment.id, tokenStr);
            if (result.status === 200) {
                showNotification('Attachment removed', 'info');
                await retrieveNoteAttachments(selectedNote);
            }
        }

    }

    return (<>
        <div className="rna-container">
            <NotificationAlert ref={notificationAlertRef} />
        </div>
        <Container fluid>
            {showForm &&
                <NoteForm note={selectedNote} noteSave={saveNoteFunc}
                    cancelEdit={cancelEdit} uploadFileFunc={updloadFile} attachments={attachments}
                    removeAttachment={removeAttachmentFunc}></NoteForm>
            }
            {!showForm &&

                <Container fluid>
                    <Row>
                        <Col md="8">
                            <Button
                                className="btn-fill pull-left"
                                onClick={showEdit}
                                variant="info"
                            >
                                New note
                            </Button>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <NotesList notes={notes} removeNoteFunc={removeNoteFunc} selectFunc={editNote}></NotesList>
                    </Row>

                </Container>



            }


        </Container>
    </>)
}

export default NotesView;