import React, { useState, useEffect, useContext } from "react";
import { config } from '../../config';
import {AuthContext} from '../../authContext';
import {fetchNotifications,acknowledgeAlert} from '../../services/httpService';
import axios from 'axios';
import {
    Card,
    Table,
    Container,
    Row,
    Col,
} from "react-bootstrap";
import PositionRow from './PositionRow';
import ExtPositionSellForm from './form/ExtPositionSellForm';
import PositionUpdateForm from './form/PositionUpdateForm';
import PositionInfoModal from './info/PositionInfo';
import PositionSellForm from './sellform/PositionSellForm';
import PositionChartModal from '../Position/chart/PositionChart';
import AlertModal from '../Position/alert/AlertModal';
import NewsListModal from '../Position/news/NewsList';

function PositionList({ queryObj }) {

    const [positions, setPositions] = useState([]);
    const [showModal, setShowModal] = useState();
    const [showUpdateModal, setShowUpdateModal] = useState();
    const [showInfoModal, setShowInfoModal] = useState();
    const [showChartModal, setShowChartModal] = useState(false);
    const [showSellPositionModal, setShowPositionModal] = useState();
    const [showAlert,setShowAlertModal] = useState();
    const [showNewsModal, setShowNewsModal] = useState(false);
    const [selectedAlert, setSelectedAlert] = useState({});
    const [alertNotifications, setAlertNotifications] = useState([]);
    const [selectedPosition, setSelectedPosition] = useState({});
    const [positionSummary, setPositionSummary] = useState({});
    const [chartData, setChartData] = useState([]);
    const ctx = useContext(AuthContext);

    const tokenStr = ctx.token.idToken.rawIdToken;

    useEffect(async () => {
        const portfolioId = queryObj.portfolioId;

       await loadPositionData(portfolioId);

    }, [queryObj])

    const listReload = async () => {
        await loadPositionData(queryObj.portfolioId);
    }

    const loadPositionData = async (portfolioId) => {

        const result = await axios(config.backendUrl + '/stock/trade/position/portfolio/' + portfolioId,{headers: {"Authorization" : `Bearer ${tokenStr}`}});
        console.log('Positions : ', result.data)
        setPositions(result.data);

        await calculateTotals(result.data);

    }

    const calculateTotals = async (positionsData) =>  {

        let totalProfitLoss = 0;
        let totalValue = 0;

        console.log('Calculating total profit and loss for portfolio, data:', positionsData);

        if(positionsData) {
            for(let pos of positionsData) {
               
               
                if(pos.currentSecurityProfitOrLossAmount) {
                   
                    totalProfitLoss = totalProfitLoss + pos.currentSecurityProfitOrLossAmount;
                }
                if(pos.currentSecurityValue) {
                    
                    totalValue = totalValue + pos.currentSecurityValue;
                }
                
            }
            
            console.log('Summary :',  totalProfitLoss); 
            console.log('Summary :',  totalValue); 
           const summaryObj = {
               totalProfit : Number(totalProfitLoss).toFixed(2),
               totalValue : Number(totalValue).toFixed(2)
           }
            
            console.log('Summary :', summaryObj);
            setPositionSummary(summaryObj);
            
        }
        

    } 

    const showSellForm = (position) => {

        console.log('Showing edit form for: ', position);
        setSelectedPosition(position);
        setShowModal(true);

    }

    const showInfoModalFunc = (position) => {

        
        setSelectedPosition(position);
        setShowInfoModal(true);

    }

    const showPositionSellModal = (position) => {

        setSelectedPosition(position);
        setShowPositionModal(true);

    }

    const cancelShowPositionSellModal = () => {

        setSelectedPosition({});
        setShowPositionModal(false);
    }

    const cancelInfoModal = () => {

        setShowInfoModal(false);
        setSelectedPosition({});
    }

    const showUpdateForm = (position) => {

        setSelectedPosition(position);
        setShowUpdateModal(true);

    }

    const cancelSellForm = () => {

        setShowModal(false);
        setShowUpdateModal(false);
        setSelectedPosition({});

    }

    const showChartModalFunc = async (position) => {

        
        if(tokenStr && position) {
            setSelectedPosition(position)
            const chartUrl = `${config.backendUrl}/stock/trade/position/history/month/${position.securityName}`;
            const chartDataResult = await axios(chartUrl,{headers: {"Authorization" : `Bearer ${tokenStr}`}});
            console.log('Chart data : ', chartDataResult)
            const reversedChartData = chartDataResult.data.reverse();
            setChartData(reversedChartData);
            setShowChartModal(true);
            
        }

    }

    const cancelChartModal = async () => {
        setShowChartModal(false);
    }

    const showAlertModal = async (position) => {
        
        if(position.alert) {
            
            setSelectedAlert(position.alert);
            setShowAlertModal(true);
        }
    }

    const acknowledgeAlertFunc = async(alert) => {
       if(tokenStr) {
        const result = await acknowledgeAlert(tokenStr,alert);
        setShowAlertModal(false);
        await reloadTable();
       } else {
           console.log('TOKEN WAS NOT DEFINED!!');
       }
     
    }

    const cancelAlertModal = async() =>  {
        setShowAlertModal(false);
    }

    const cancelNewsModal = async() => {
        setShowNewsModal(false);
    }

    const showNews = async(position) => {

        setSelectedPosition(position);
        
        setShowNewsModal(true);

    }

    const reloadTable = async () => {

        
        const portfolioId = queryObj.portfolioId;

       await loadPositionData(portfolioId);
    }

    return (
        <Container fluid>
            <Row>
                <Col md="12">
                    <Card className="strpied-tabled-with-hover">
                        <Card.Header>
                            <Card.Title as="h4">Positions</Card.Title>
                            <p className="card-category">
                                Here is a list of your positions
                            </p>
                            <p className="card-category">
                            Total profit and loss : {positionSummary.totalProfit}
                            
                            </p>
                            <p className="card-category">
                            Total value : {positionSummary.totalValue}
                            </p>
                        </Card.Header>
                        <Card.Body className="table-full-width table-responsive px-0">
                           
                            <Table className="table-hover table-striped">
                                <thead>
                                    <tr>
                                        <th className="border-0">Security name</th>
                                        <th className="border-0">Security count</th>
                                        <th className="border-0">Current value</th>
                                        <th className="border-0">Profit or loss</th>
                                        <th className="border-0">Analyst recommendation</th>
                                        <th className="border-0">Analyst price</th>
                                        <th className="border-0">Security price</th>
                                        <th className="border-0">Update date</th>
                                        <th className="border-0">Alerts</th>
                                        <th className="border-0"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {positions &&
                                        positions.map(item => (
                                            <PositionRow key={item.id} position={item} editSellPosition={showSellForm} 
                                            editPosition={showUpdateForm} showPositionInfo={showInfoModalFunc} 
                                            showPositionSellModal={showPositionSellModal} showChartModalFunc={showChartModalFunc} 
                                            showAlertModalFunc={showAlertModal} showNewsModalFunc={showNews}></PositionRow>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <ExtPositionSellForm modalShow={showModal} position={selectedPosition} cancel={cancelSellForm} reloadList={listReload}></ExtPositionSellForm>
            <PositionUpdateForm modalShow={showUpdateModal} position={selectedPosition} cancel={cancelSellForm}></PositionUpdateForm>
            <PositionInfoModal modalShow={showInfoModal} position={selectedPosition} cancel={cancelInfoModal}></PositionInfoModal>
            <PositionSellForm modalShow={showSellPositionModal} position={selectedPosition} cancel={cancelShowPositionSellModal} reloadTable={reloadTable}></PositionSellForm>
            <PositionChartModal modalShow={showChartModal} data={chartData} position={selectedPosition} cancel={cancelChartModal}></PositionChartModal>
            <AlertModal modalShow={showAlert} positionAlert={selectedAlert} notifications={alertNotifications} acknowledgeFunc={acknowledgeAlertFunc} cancel={cancelAlertModal}></AlertModal>
            <NewsListModal modalShow={showNewsModal} position={selectedPosition} cancel={cancelNewsModal}></NewsListModal>
        </Container>
    )

}

export default PositionList;
