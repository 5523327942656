import React, { Component } from 'react';
import {
  msalApp,
  requiresInteraction,
 
} from './authUtils';
import { AuthContext } from './authContext';

const scopes = ["api://c81344e0-6406-4cf0-865a-2e0fc72aa75f/Access.api"];

export default (C) =>
  class AuthProvider extends Component {
    constructor(props) {
      super(props);

      this.state = {
        account: null,
        token: null,
        isAuthenticated: false,
        error: null,
      };
    }

    async acquireToken(request, redirect) {
      console.log('acquireToken - request : ', request);
      return msalApp
        .acquireTokenSilent(request)
        .then((loginResponse) => {
          if (loginResponse) {
            this.setState({
              account: loginResponse.account,
              isAuthenticated: true,
              error: null,
            });
            return true;
          }
        })
        .catch((error) => {

          this.setState({
            account: null,
            isAuthenticated: false,
            token: null,
            error: null,
          });
          this.onSignIn();
          // Call acquireTokenPopup (popup window) in case of acquireTokenSilent failure
          // due to consent or interaction required ONLY
          if (requiresInteraction(error.errorCode)) {
            this.setState({
              isAuthenticated: false,
            });
            return redirect ? msalApp.acquireTokenRedirect(request) : this.onSignIn(redirect);
          }
          console.error('Non-interactive error:', error.errorCode);
          return false;
        });
    }

    async getToken() {

      if(this.token) {
        await this.CheckIsAuthenticated();
        return token;
      } else {
        await this.onSignIn()
      }

    }

    async onSignIn() {
     
      const loginResponse = await msalApp.loginPopup({
        scopes: scopes,
        extraQueryParameters: {
          ui_locales: localStorage.getItem('language') ?? 'sv',
        },
      });

      

      if (loginResponse) {
        console.log('Login response : ', loginResponse);
        const request = {
          scopes: scopes,
          account: loginResponse.account
      };

      console.log('Request : ', request)
        //const tokenResponse = await msalApp.acquireTokenSilent(request);
        const tokenResponse = await msalApp.acquireTokenPopup(request);
        console.log('Token response : ', tokenResponse);
        if(tokenResponse) {
          this.setState({
            account: loginResponse.account,
            isAuthenticated: true,
            token: tokenResponse,
            error: null,
          });
        }
      
      }
    }

    onSignOut() {
      msalApp.logout();
    }

    async componentDidMount() {

      console.log('Component did mount :', this.state)
      msalApp.handleRedirectCallback((error) => {
        if (error) {
          const errorMessage = error.errorMessage ? error.errorMessage : 'Unable to acquire access token.';
          this.setState({
            error: errorMessage,
          });
        } 
      });

      if(this.state.error) {
        console.log('Got error : ', this.state.error);
      } else {
        const account = msalApp.getAccount();
        console.log('Retrieved account : ', account);
        if(account) {
          console.log('Got account :', account );
          try {
            const request = {
              scopes: scopes,
              account: account
          };
            const tokenResponse = await msalApp.acquireTokenSilent(request);
            console.log('Got token : ', tokenResponse);
            if(tokenResponse) {
              this.setState({
                account : account,
                isAuthenticated: true,
                token: tokenResponse
              });
            } else {
              console.log('Did not get tokenResponse : ', tokenResponse)
              this.onSignIn();
            }
          } catch(e) {
            console.log('Exception occurred when trying to acquire token : ',e);
            this.onSignIn();
          }

         
        } else {
          this.onSignIn();
        }
      
      }
    }

    async CheckIsAuthenticated() {
      const account = msalApp.getAccount();
      this.setState({
        account,
      });

      const now = Math.round(new Date().getTime() / 1000);

      if (account) {
        if (account.idToken.exp > now) {
          this.setState({
            isAuthenticated: true,
          });
          return true;
        }
        return this.acquireToken(AUTH_REQUESTS.REFRESH_TOKEN, useRedirectFlow);
      }
      return false;
    }

    render() {
      const authContext = {
        isAuthenticated: this.state.isAuthenticated,
        CheckIsAuthenticated: () => this.CheckIsAuthenticated(),
        token: this.state.token,
        account: this.state.account,
        error: this.state.error,
        getToken: () => this.getToken(),
        onSignIn: () => this.onSignIn(),
        onSignOut: () => this.onSignOut(),
      };

      return (
        <AuthContext.Provider value={authContext}>
          <C {...this.props} />
        </AuthContext.Provider>
      );
    }
  };