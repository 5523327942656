import React, { useState, useEffect, useContext } from "react";
import {
    Card,
    Form,
    Container,
    Button,
    Row,
    Col,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import PortfolioSelect from '../portfolio/PortfolioSelect';
import axios from 'axios';
import { config } from '../../config';
import {AuthContext} from '../../authContext';


import "react-datepicker/dist/react-datepicker.css";

function SecuritySearch({ onSearch }) {

    const [fromDate, setFromDate] = useState(new Date());

    const [toDate, setToDate] = useState(new Date());

    const [portfolioId, setPortfolioId] = useState([]);

    const [portfolios, setPortfolios] = useState([]);

    const ctx = useContext(AuthContext);
    const tokenStr = ctx.token.idToken.rawIdToken;

    useEffect(async () => {
        console.log('Token str: ', tokenStr)
        const result = await axios(config.backendUrl + '/portfolio',{headers: {"Authorization" : `Bearer ${tokenStr}`}});
        
        setPortfolios(result.data);
    }, []);

   const onPortfolioSelected = (selectedPortfolio) => {

        setPortfolioId(selectedPortfolio);

    }

   const onSearchButtonPressed = () => {

        const searchObj = {
            fromDate : fromDate,
            toDate: toDate,
            portFolioId: portfolioId
        }

        onSearch(searchObj);

    }
  
    return (
        <Container fluid>
            <Row>
                <Col md="10">
                    <Card>
                        <Card.Header>
                            <Card.Title as="h4">Search trades between dates</Card.Title>
                        </Card.Header>
                        <Card.Body>
                        <Row>
                            <Col className="px-1" md="3">
                                <Form.Group>
                                    <label>From date</label>
                                    <DatePicker className="input form-control" dateFormat="dd.MM.yyyy" selected={fromDate} onChange={date => setFromDate(date)} />

                                </Form.Group>
                            </Col>
                            <Col className="px-1" md="3">
                                <Form.Group>
                                    <label>To date</label>
                                    <DatePicker className="input form-control" dateFormat="dd.MM.yyyy" selected={toDate} onChange={date => setToDate(date)} />

                                </Form.Group>
                            </Col>
                            <Col className="px-1" md="4">
                                <PortfolioSelect portfolios={portfolios} valueSelected={onPortfolioSelected}></PortfolioSelect>
                            </Col>
                            </Row>
                           
                            <Row>
                            <Col className="px-1" md="1">
                                        <Button
                                            className="btn-fill pull-right"
                                            onClick={onSearchButtonPressed}
                                            variant="info"
                                        >
                                            Search
                                        </Button>
                                    </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default SecuritySearch;