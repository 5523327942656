import React from "react";
import SettingRow from './SettingRow';

import {
    Badge,
    Button,
    Card,
    Navbar,
    Nav,
    Table,
    Container,
    Row,
    Col,
  } from "react-bootstrap";

function SettingsList({settings, editSetting, removeSetting}) {

    

    return(
        <Container fluid>
             <Card className="strpied-tabled-with-hover">
                <Card.Header>
                            <Card.Title as="h4">Settings</Card.Title>
                            <p className="card-category">
                                List of settings
                </p>
                        </Card.Header>
                        <Card.Body className="table-full-width table-responsive px-0">
                        <Table className="table-hover table-striped">
                                <thead>
                                    <tr>
                                        <th className="border-0">Setting name</th>
                                        <th className="border-0">Setting value</th>
                                        
                                    </tr>
                                </thead>
                                <tbody>
                                    { settings &&
                                        settings.map(item => (
                                            <SettingRow key={item.id} settingRow={item} editSettingFunc={editSetting} removeSettingFunc={removeSetting}></SettingRow>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        </Card.Body>
                        </Card>
        </Container>
    )
}


export default SettingsList;