import React,  { useState, useEffect, useContext }  from "react";
import {AuthContext} from '../authContext';
import { useHistory } from "react-router-dom";
import {fetchSettings,fetchSettingTypes,fetchSettingNames,saveSetting, removeSetting} from '../services/httpService';
import SettingsList from '../components/Setting/SettingList';
import SettingForm from '../components/Setting/SettingForm';
import NotificationAlert from "react-notification-alert";
import {showNotification} from '../components/Common/NotificationOptions';
import moment from 'moment';
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
} from "react-bootstrap";

function SettingsView() {

    const [settings, setSettings] = useState([]);
    const [settingTypes, setSettingTypes] = useState([]);
    const [settingNames, setSettingNames] = useState([]);
    const [selectedSetting,setSelectedSetting] = useState({});
    const notificationAlertRef = React.useRef(null);

    const ctx = useContext(AuthContext);
    const history = useHistory();
    let tokenStr;
    
    if (ctx && ctx.token && ctx.token.idToken) {
      tokenStr = ctx.token.idToken.rawIdToken;
    } else {
      history.push('/');
    }
    
    useEffect(async () => {
      
      const result = await fetchSettings(tokenStr);
      setSettings(result.data);
      const settingTypeResults = await fetchSettingTypes(tokenStr);
      setSettingTypes(settingTypeResults.data);
      const settingNameResults = await fetchSettingNames(tokenStr);
      setSettingNames(settingNameResults.data);
      
    },[]);

   

    const removeSettingFunc = (setting) => {
      if(setting && setting.id) {
        removeSetting(setting.id, tokenStr).then(res => {
          
          if(res.status === 200) {
            fetchSettings(tokenStr).then(result => {
              setSettings(result.data);
            showNotification('Setting removed!', 'info',notificationAlertRef);
            })
          }
          
        })
      }
    }

    const editSetting = (setting) => {
      console.log('Setting : ', setting)
      setSelectedSetting(setting);
      console.log('Selected setting : ' ,selectedSetting)
    }

    const saveSettingFunc = (setting) => {
      
      saveSetting(setting,tokenStr).then(result => {

        if(result.status === 200) {
          fetchSettings(tokenStr).then(res => {
            setSettings(res.data);
          showNotification('Setting saved!', 'info',notificationAlertRef);
          })
        }
        
        
      })
      
      

    }

    const cancelSettingEdit = () => {
      setSelectedSetting({});
      
    }

    return (
        <Container fluid>
              <div className="rna-container">
                <NotificationAlert ref={notificationAlertRef} />
    </div>
          <SettingForm setting={selectedSetting} settingTypes={settingTypes} settingNames={settingNames} saveSetting={saveSettingFunc} cancel={cancelSettingEdit} notificationAlertRef={notificationAlertRef}></SettingForm>
          <SettingsList settings={settings} editSetting={editSetting} removeSetting={removeSettingFunc}></SettingsList>
        </Container>
    )

}

export default SettingsView;