import axios from 'axios';
import { config } from '../config';

const createHeaders = (tokenStr) =>{
    return {headers: {"Authorization" : `Bearer ${tokenStr}`}}
}

const createAuthHeader = (tokenStr) => {
    return { "Authorization": `Bearer ${tokenStr}` };
}


export const fetchSettingTypes = async (tokenStr) => {
    const settingTypesUrl = `${config.backendUrl}/setting/type`;
    return await axios(settingTypesUrl,createHeaders(tokenStr));
}

export const fetchNotifications = async(tokenStr, ids) => {
    const idsStr = ids.join(",");
    const notificationsUrl = `${config.backendUrl}/stock/trade/notifications/find?ids=${idsStr}`;
    return await axios (notificationsUrl, createHeaders(tokenStr));
}

export const acknowledgeAlert = async(tokenStr, alert) => {
    const ackUrl = `${config.backendUrl}/stock/trade/alert/ack/${alert.alertKey}`
    return await axios({
        method: 'POST',
        url: ackUrl, 
        headers: createAuthHeader(tokenStr)});
}

export const fetchSettingNames = async (tokenStr) => {
    const settingNamesUrl = `${config.backendUrl}/setting/name`;
    return await axios(settingNamesUrl,createHeaders(tokenStr));
}

export const fetchSettings = async(tokenStr) => {
    const settingsUrl = `${config.backendUrl}/setting`;
    return await axios(settingsUrl,createHeaders(tokenStr));
}

export const saveSetting = async(setting, tokenStr) => {
    const settingsUrl = `${config.backendUrl}/setting`;
    return await axios({
        method: 'POST',
        url: settingsUrl,
        data: setting,
        headers: createAuthHeader(tokenStr)
    });
}

export const removeSetting = async(id, tokenStr) => {
    const settingRemoveUrl = `${config.backendUrl}/setting/${id}`;
    return await axios({
        method: 'DELETE',
        url: settingRemoveUrl,
        headers: createAuthHeader(tokenStr)
    })
}

export const listNotes = async(tokenStr) => {

    const notesListUrl = `${config.securityNotesBackendUrl}`;
    return await axios({
        method: 'GET',
        url: notesListUrl,
        headers: createAuthHeader(tokenStr)
    });

}

export const saveNote = async(data, tokenStr) => {

    const notesSaveUrl = `${config.securityNotesBackendUrl}`;
    return await axios({
        method: 'POST',
        url: notesSaveUrl,
        data: data,
        headers: createAuthHeader(tokenStr)
    });

}

export const removeNote = async(id, tokenStr) => {

    const notesRemoveUrl = `${config.securityNotesBackendUrl}/${id}`;
    return await axios({
        method: 'DELETE',
        url: notesRemoveUrl,
        headers: createAuthHeader(tokenStr)
    });
}

export const saveAttachment = async(attachment, noteId, tokenStr) => {

    const attachmentSaveUrl = `${config.securityNotesBackendUrl}/attachment/${noteId}`;
    return await axios({
        method: 'POST',
        url: attachmentSaveUrl,
        data: attachment,
        headers: createAuthHeader(tokenStr)
    });

}

export const listSecurityNewsWithIsin = async(isin) => {

    const securityNewsUrl = `${config.securityNewsBackendUrl}/security/isin/${isin}`;
    return await axios(securityNewsUrl);

}

export const listNoteAttachments = async(noteId, tokenStr) => {

    const attachmentListUrl = `${config.securityNotesBackendUrl}/attachment/${noteId}`;
    return await axios(attachmentListUrl,createHeaders(tokenStr));
    
}

export const removeAttachment = async(attachmentId, tokenStr) => {

    const attachmentUrl = `${config.securityNotesBackendUrl}/attachment/${attachmentId}`;

    return await axios({
        method: 'DELETE',
        url : attachmentUrl,
        headers: createAuthHeader(tokenStr)
    })

}