import React,  { useState, useEffect, useContext }  from "react";
import RealizedProfitOrLossList from '../components/RealizedProfitOrLoss/RealizedProfitOrLossList';

function ProfitOrLossView() {

    return (
        <RealizedProfitOrLossList></RealizedProfitOrLossList>
    )

}

export default ProfitOrLossView;