import React, { useState, useEffect,useContext } from "react";
import { config } from '../../config';
import PortfolioSelect from '../portfolio/PortfolioSelect';
import NotificationAlert from "react-notification-alert";
import {
    Badge,
    Button,
    Card,
    Form,
    Navbar,
    Nav,
    Container,
    Row,
    Col,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import axios from 'axios';
import {AuthContext} from '../../authContext';

function DepositForm() {

    const { register, handleSubmit, watch, errors } = useForm();
    const notificationAlertRef = React.useRef(null);
    const ctx = useContext(AuthContext);
    const tokenStr = ctx.token.idToken.rawIdToken;

    const [data, setData] = useState([]);

    const [selectedPortfolioId, selectPortfolioId] = useState();

    useEffect(async () => {
        const result = await axios(config.backendUrl + '/portfolio',{headers: {"Authorization" : `Bearer ${tokenStr}`}});
        setData(result.data);
    }, []);

    const showNotification = (message, type) => {

        let options = {};
        options = {
            place: 'tc',
            message: (
                <div>
                    <div>
                        {message}
                    </div>
                </div>
            ),
            type: type,
            icon: "nc-icon nc-bell-55",
            autoDismiss: 7,
        };
        notificationAlertRef.current.notificationAlert(options);

    }

    const onPortfolioSelected = (selectedPortfolio) => {

        selectPortfolioId(selectedPortfolio);

    }

    const onFormSubmit = (pdata, e) => {



        pdata.portfolioId = selectedPortfolioId;

        axios({
            method: 'POST',
            url: config.backendUrl + '/portfolio/deposit',
            data: pdata
        }).then(result => {
            console.log('Got result : ', result);
            if (result.status == 200) {
                showNotification('Deposit saved', 'info');
                e.target.reset();
            }
        })
    }

    return (
        <Container fluid>
            <div className="rna-container">
                <NotificationAlert ref={notificationAlertRef} />
            </div>
            <Row>
                <Col md="6">
                    <Card>
                        <Card.Header>
                            <Card.Title as="h4">Add deposit</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Form onSubmit={handleSubmit(onFormSubmit)}>
                                <Row>
                                    <Col className="px-1" md="4">
                                        <Form.Group>
                                            <label>Amount</label>
                                            <Form.Control
                                                placeholder="Amount"
                                                name="depositAmount"
                                                ref={register}
                                                type="text"
                                            >

                                            </Form.Control>

                                        </Form.Group>
                                    </Col>

                                    <Col className="px-1" md="4">
                                        <Form.Group>
                                            <label>Description</label>
                                            <Form.Control
                                                placeholder="Description"
                                                name="description"
                                                ref={register}
                                                type="text"
                                            >

                                            </Form.Control>

                                        </Form.Group>
                                    </Col>
                                    <Col className="px-1" md="4">
                                        <Form.Group>

                                            <PortfolioSelect portfolios={data} valueSelected={onPortfolioSelected}></PortfolioSelect>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="px-1" md="1">
                                        <Button
                                            className="btn-fill pull-right"
                                            type="submit"
                                            variant="info"
                                        >
                                            Save
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

        </Container>
    )
}

export default DepositForm;