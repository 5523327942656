import React, { useState, useContext, useEffect } from "react";
import { config } from '../config';
import { getRandomNumber } from '../components/utils';
import { AuthContext } from '../authContext';
import { useHistory } from "react-router-dom";
import { showNotification } from '../components/Common/NotificationOptions';
import PortfolioAlertForm from '../components/PortfolioAlert/PortfolioAlertForm';
import PortfolioAlertList from '../components/PortfolioAlert/PortfolioAlertList';
import NotificationAlert from "react-notification-alert";
import axios from "axios";
import { Container } from "react-bootstrap";


function PortfolioAlerts() {

  const [refreshVar, setRefreshVar] = useState({});
  const [portfolioData, setPortfolioData] = useState([]);
  const [portfolioAlerts, setPortfolioAlerts] = useState([]);

  const initialSelectPortfolioAlert = {
    id: undefined,
    portfolioId: undefined,
    minimumProfit: 0.0,
    minimumValue: 0.0
  };

  const [selectedPortfolioAlert, setSelectedPortfolioAlert] = useState(initialSelectPortfolioAlert);



  const notificationAlertRef = React.useRef(null);

  const ctx = useContext(AuthContext);
  const history = useHistory();
  let tokenStr;

  if (ctx && ctx.token && ctx.token.idToken) {
    tokenStr = ctx.token.idToken.rawIdToken;
  } else {
    history.push('/');
  }



  useEffect(async () => {
    const result = await axios(config.backendUrl + '/portfolio', { headers: { "Authorization": `Bearer ${tokenStr}` } });
    setPortfolioData(result.data);
  }, []);

  useEffect(async () => {
    const result = await axios(config.backendUrl + '/portfolio/alert', { headers: { "Authorization": `Bearer ${tokenStr}` } });
    setPortfolioAlerts(result.data);
  }, [refreshVar])

  const showNotificationMessage = (message, type) => {
    showNotification(message, type, notificationAlertRef);
  }

  const editPortfolioAlert = (alert) => {

    setSelectedPortfolioAlert(alert);

  }

  const deletePortfolioAlert = (alert) => {

    console.log('Deleting portfolio alert :', alert);

    const result = axios({
      method: 'DELETE',
      url: config.backendUrl + '/portfolio/alert/' + alert.id,
      headers: { "Authorization": `Bearer ${tokenStr}` }
    })

    result.then(() => {

      setRefreshVar(getRandomNumber(1000));
      showNotificationMessage('Portfolio alert removed', 'info');
    })

  }

  const onPortfolioAlertSave = (portfolioSave, resetForm) => {

    console.log('Saving portfolio : ', portfolioSave)

    axios({
      method: 'POST',
      url: config.backendUrl + '/portfolio/alert',
      data: portfolioSave,
      headers: { "Authorization": `Bearer ${tokenStr}` }
    }).then((result) => {
      console.log('Save result : ', result);
      if (result.status == 200) {
        showNotificationMessage('Portfolio alert saved', 'info');

        setRefreshVar(getRandomNumber(1000));
        setSelectedPortfolioAlert(initialSelectPortfolioAlert);
        resetForm();
        //refreshTable(randomNum);
        //resetForm();
        //e.target.reset();
      }
    })

  }

  const onPortfolioAlertCancel = () => {
    console.log('Portfolio alert cancel')
  }

  return (
    <>
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Container fluid>
        <PortfolioAlertForm onSave={onPortfolioAlertSave} onCancel={onPortfolioAlertCancel}
          showNotification={showNotificationMessage} portfolios={portfolioData}
          selectedAlertParam={selectedPortfolioAlert}></PortfolioAlertForm>
        <PortfolioAlertList portfolioAlerts={portfolioAlerts} onEdit={editPortfolioAlert} onDelete={deletePortfolioAlert}></PortfolioAlertList>
      </Container>

    </>
  )


}

export default PortfolioAlerts;