import React, { useState, useEffect, useContext } from "react";
import PortfolioListRow from './PortfolioListRow';
import {AuthContext} from '../../authContext';
import {config} from '../../config';
import axios from 'axios';
import {
    Badge,
    Button,
    Card,
    Navbar,
    Nav,
    Table,
    Container,
    Row,
    Col,
} from "react-bootstrap";

function PortfolioList({needRefresh,onPortfolioSelected}) {

    const [data, setData] = useState([]);

    const ctx = useContext(AuthContext);

    console.log('Ctx : ', ctx);
    
    const tokenStr = ctx.token.idToken.rawIdToken;

    const rowSelected = (row) => {
        onPortfolioSelected(row);
    }

    let fetchData = needRefresh;

    useEffect(async () => {
        const result = await axios(config.backendUrl +'/portfolio',{headers: {"Authorization" : `Bearer ${tokenStr}`}});
        console.log('Got data : ',result);
        setData(result.data);
    }, [fetchData])

    return (
        <Container fluid>
            <Row>
                <Col md="8">
                    <Card className="strpied-tabled-with-hover">

                        <Card.Header>
                            <Card.Title as="h4">Portfolios</Card.Title>
                            <p className="card-category">
                                Here is a list of your portfolios
                </p>
                        </Card.Header>
                        <Card.Body className="table-full-width table-responsive px-0">
                            <Table className="table-hover table-striped">
                                <thead>
                                    <tr>
                                        <th className="border-0">Name</th>
                                        <th className="border-0">Description</th>
                                        <th className="border-0">Holder</th>
                                        <th className="border-0">Code</th>
                                        <th className="border-0"></th>
                                        
                                    </tr>
                                </thead>
                                <tbody>
                                    { data &&
                                        data.map(item => (
                                            <PortfolioListRow key={item.id} portfolio={item} onRowSelected={rowSelected}></PortfolioListRow>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>

                </Col>
            </Row>
        </Container>

    );

}

export default PortfolioList;