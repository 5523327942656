import React, { useState, useEffect, useContext } from "react";
import {
    Button,
    Card
} from "react-bootstrap";

function NoteAttachment({ attachment, removeAttachmentFunc }) {


    const removeAttachment = () => {

        removeAttachmentFunc(attachment);

    }

    return (<>

        <div>
            <Card style={{ width: '60rem' }}>
                <Card.Img variant="top" src={attachment.attachmentUrl} />
                <Card.Body>
                    <Card.Title>{attachment.attachmentName}</Card.Title>
                    <br/>
                    <Button variant="danger" onClick={removeAttachment}>Remove</Button>
                </Card.Body>
            </Card>

        </div>

    </>)
}

export default NoteAttachment;