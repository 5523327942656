import React, { useState, useEffect } from "react";
import SettingSelect from "./SettingSelects/SettingSelect";
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Form,
  Row,
  Col,
} from "react-bootstrap";
import { showNotification } from '../Common/NotificationOptions';
import { useForm, Controller } from "react-hook-form";
import NumberFormat from "react-number-format";

function SettingForm({
  setting,
  settingTypes,
  settingNames,
  saveSetting,
  cancel,
  notificationAlertRef
}) {
  const [settingType, setSelectedSettingType] = useState();
  const [settingName, setSelectedSettingName] = useState();
  const { handleSubmit, register, reset, control } = useForm();


  useEffect(() => {
    renderSettingValue();

  }, [settingType]);

  useEffect(() => {
    if (setting) {
      onSettingTypeSelect(setting.settingType);
      onSettingNameSelect(setting.settingName);
    }
  }, [setting]);

  const onSettingTypeSelect = (settingType) => {

    setSelectedSettingType(settingType);

  };

  const onSettingNameSelect = (settingName) => {
    setSelectedSettingName(settingName);
  };

  const renderSettingValue = () => {
    if (settingType === "STRING") {
      return (
        <Form.Group>
          <label>Setting text value</label>
          <Form.Control
            placeholder="Setting value"
            name="settingValue"
            defaultValue={setting.settingValue}
            ref={register}
            type="text"
          ></Form.Control>
        </Form.Group>
      );
    } else if (settingType === "BOOL") {
      return (
        <Form.Group>
          <label>Setting boolean value</label>
          <Form.Check
            type="switch"
            name="settingValueBool"
            defaultValue={setting.settingValueBool}
            ref={register}
            defaultChecked={true}
            id="custom-switch"
          />
        </Form.Group>
      );
    } else if (settingType === "DOUBLE") {
      return (
        <Form.Group>
          <label>Setting number value</label>
          <Controller
            as={NumberFormat}
            control={control}
            defaultValue={setting.settingValueDouble}
            name="settingValueDouble"
            className="input form-control"
          />
        </Form.Group>
      );
    } else {
      return <div></div>;
    }
  };

  const onFormSubmit = (stdata, e) => {

    const formData = {
      'settingName': settingName,
      'settingType': settingType,
      ...stdata
    };

    if (settingName === undefined || settingName === null) {
      showNotification('Setting name must be selected!', 'warning', notificationAlertRef);
      return;
    }
    if (settingType === undefined || settingType === null) {
      showNotification('Setting type must be selected!', 'warning', notificationAlertRef);
      return;
    }
    saveSetting(formData);

  };

  const onClear = () => {
    cancel();
  }

  return (
    <Container fluid>

      <Card>
        <Card.Header>
          <Card.Title as="h4">Save setting</Card.Title>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit(onFormSubmit)}>
            <Row md={1}>
              <Col className="px-1" md="3">
                <Form.Group>
                  <SettingSelect
                    settingSelectValues={settingTypes}
                    selectLabel="Select setting type"
                    valueSelected={onSettingTypeSelect}
                    selectedVal={setting.settingType}
                  ></SettingSelect>
                  <SettingSelect
                    settingSelectValues={settingNames}
                    selectLabel="Select setting name"
                    valueSelected={onSettingNameSelect}
                    selectedVal={setting.settingName}
                  ></SettingSelect>
                </Form.Group>
                {renderSettingValue()}
              </Col>
            </Row>
            <Row>
              <Col className="px-1" md="1">
                <Button
                  className="btn-fill pull-right"
                  type="submit"
                  variant="info"
                >
                  Save
                </Button>
              </Col>
              <Col className="px-1" md="2">
                <Button
                  className="btn-fill pull-right"
                  onClick={onClear}
                  variant="info"
                >
                  Clear
                </Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default SettingForm;
