import React, { useContext, useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
import { useForm, Controller } from "react-hook-form";
import { config } from '../../../config';
import { AuthContext } from '../../../authContext';
import {
    Badge,
    Button,
    Card,
    Form,
    Navbar,
    Nav,
    Container,
    Row,
    Col,
} from "react-bootstrap";
import NumberFormat from 'react-number-format';

function PositionUpdateForm({ modalShow, position, cancel }) {


    const ctx = useContext(AuthContext);

    const tokenStr = ctx.token.idToken.rawIdToken;

    const notificationAlertRef = React.useRef(null);
    
     const [selectedPosition, setSelectedPosition] = useState({
        isin: position.securityIsin,
        initialSecurityPurchasePrice: position.initialPurchasePrice
    });

    const { handleSubmit, register, reset, control } = useForm({defaultValues: selectedPosition});

   

    useEffect(async () => {

        reset({values: position});
        if(position) {
          
            setSelectedPosition({
            isin: position.securityIsin,
            initialSecurityPurchasePrice: position.initialPurchasePrice
        });
        }
    
      }, [position]);

    

    const showNotification = (message, type) => {

        let options = {};
        options = {
            place: 'tc',
            message: (
                <div>
                    <div>
                        {message}
                    </div>
                </div>
            ),
            type: type,
            icon: "nc-icon nc-bell-55",
            autoDismiss: 7,
        };
        notificationAlertRef.current.notificationAlert(options);

    }
    

    const updatePosition = (formData, e) => {

        formData.id = position.id;

        const url = config.backendUrl + '/stock/trade/position';

        console.log('Updating position : ', formData);

        axios({
            method: 'PUT',
            url: url,
            data: formData,
            headers: { "Authorization": `Bearer ${tokenStr}` }
        }).then(result => {
            if (result.status == 200) {
                showNotification('Position updated', 'info');
                cancel();
            }
        })



    }

    console.log('PositionUpdateForm selectedPosition: ', selectedPosition);
    console.log('Position update : ', position)

    return (
        <>
            <div className="rna-container">
                <NotificationAlert ref={notificationAlertRef} />
            </div>
            <Modal show={modalShow} onHide={() => cancel()}>
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        <b>Update position</b>
                    </Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmit(updatePosition)}>
                    <Modal.Body>
                        <Row>
                            <Col className="px-1" md="8">
                                <Form.Group>
                                    <label>Isin</label>
                                    <Form.Control
                                        defaultValue={selectedPosition.isin}
                                        placeholder="isin"
                                        name="isin"
                                        ref={register()}
                                        type="text"
                                    ></Form.Control>

                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="px-1" md="8">
                                <Form.Group>
                                    <label>Initial security purchase price</label>
                                    
                                    <Controller
                                        as={NumberFormat}
                                        defaultValue={selectedPosition.initialSecurityPurchasePrice}
                                        value={selectedPosition.initialSecurityPurchasePrice}
                                        name="initialSecurityPurchasePrice"
                                        className="input form-control"
                                        control={control}
                                    />
                                    
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={cancel}>Close</Button>
                        <Button variant="info" type="submit">Update</Button>

                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )

}

export default PositionUpdateForm;