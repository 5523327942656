import React from "react";
import Modal from 'react-bootstrap/Modal';
import {
    Row,
    Button
} from "react-bootstrap";

function PositionInfoModal({ modalShow, position, cancel }) {


    console.log('Showing position: ', position);
    return (
        <>
            <Modal show={modalShow} onHide={() => cancel()}>
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        <b>Position details {position.securityName}</b>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Row>
                        <p>Isin : <b>{position.securityIsin}</b></p>
                    </Row>
                    <Row>
                        <p>Security count: <b>{position.securityCount}</b></p>
                    </Row>
                    <Row>
                        <p>Current total value: <b>{position.currentSecurityValue}</b></p>
                    </Row>
                    <Row>
                        <p>Current profit or loss: <b>{position.currentSecurityProfitOrLossAmount}</b></p>
                    </Row>
                    <Row>
                        <p>Percentage change total: <b>{position.percentChangeTotal} %</b></p>
                    </Row>
                    <Row>
                        <p>Percentage change today: <b>{position.percentChangeToday} %</b></p>
                    </Row>
                    <Row>
                        <p>Single security value: <b>{position.singleSecurityValue}</b></p>
                    </Row>
                    <Row>
                        <p>Initial purchase price: <b>{position.initialPurchasePrice}</b></p>
                    </Row>
                    <Row>
                        <p>Analyst target price: <b>{position.analysisTargetPrice}</b></p>
                    </Row>
                    <Row>
                        <p>Analyst recommendation: <b>{position.recommendation}</b></p>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={cancel}>Close</Button>

                </Modal.Footer>

            </Modal>
        </>
    )

}

export default PositionInfoModal;