import React from "react";
import {
  Button,

} from "react-bootstrap";



  function PortfolioListRow({portfolio, onRowSelected}) {

    const rowSelected = (event) => {
      
      onRowSelected(portfolio);

    }

    return (<tr>
        <td>{portfolio.portfolioName}</td>
        <td>{portfolio.description}</td>
        <td>{portfolio.portfolioHolder}</td>
        <td>{portfolio.portfolioCode}</td>
        <td><Button variant="link" onClick={rowSelected}>Select</Button></td>
      </tr>)


  }

  export default PortfolioListRow;