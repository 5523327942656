import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Moment from 'react-moment';
import {
    Row,
    Button
} from "react-bootstrap";

import {listSecurityNewsWithIsin} from '../../../services/httpService';

function NewsListModal({modalShow, position, cancel}) {

    const [news, setNews] = useState([]);

    useEffect(async () => {
        if(position.securityIsin) {
            await loadNews(position.securityIsin);
        }
    }, [position])

    const loadNews = async(securityIsin) => {
        const result = await listSecurityNewsWithIsin(securityIsin);
        //TODO: Check if result is success
        console.log('Result: ', result.data);
        setNews(result.data);
    }

    return(
        <>
            <Modal show={modalShow} onHide={() => cancel()}>
            <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        <b>Position {position.securityName} news</b>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    
                    {news && news.map(n => (
                        <Row>
                             
                            <p><b>{n.title}</b></p>
                            <br/>
                            <p>
                                <Moment format="DD.MM.YYYY hh:mm">
                                    {n.published}
                                </Moment>
                            </p>
                            <p>{n.teaserText}</p>
                            <br/>
                            <p><a href={n.url} target="_blank">Show news</a></p>
                        
                        </Row>
                       )
                        
                    )}
                </Modal.Body>
            </Modal>
        </>
    )
}

export default NewsListModal;