import React, { useState, useEffect, useContext } from "react";
import { config } from '../../config';
import PortfolioSelect from '../portfolio/PortfolioSelect';
import { getRandomNumber } from '../utils';
import {
    Badge,
    Button,
    Card,
    Form,
    Navbar,
    Nav,
    Container,
    Row,
    Col,
} from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import NumberFormat from 'react-number-format';
import axios from 'axios';
import DatePicker from "react-datepicker";
import {AuthContext} from '../../authContext';
import "react-datepicker/dist/react-datepicker.css";

function SecurityTradeForm({ selectedTrade, resetForm, showNotification }) {

    const { handleSubmit, register, reset, control } = useForm();
    
    const [data, setData] = useState([]);
    const [selectedPortfolioId, selectPortfolioId] = useState();
    const [transactionDate,setTransactionDate] = useState();
    const ctx = useContext(AuthContext);
    const tokenStr = ctx.token.idToken.rawIdToken;

    const notificationAlertRef = React.useRef(null);

    useEffect(async () => {
        const result = await axios(config.backendUrl + '/portfolio',{headers: {"Authorization" : `Bearer ${tokenStr}`}});
        setData(result.data);
    }, []);


    const onPortfolioSelected = (selectedPortfolio) => {

        selectPortfolioId(selectedPortfolio);

    }

    const validateForm = (formdata) => {

        
        if(!selectedPortfolioId) {
            showNotification('Portfolio is required!','warning');
            return false;
        }

        if(!formdata.securityName) {
            showNotification('Security name is required!','warning');
            return false;
        }
        if(!formdata.numberOfSecuritiesBoughtOrSold) {
            showNotification('Number of securities is required!','warning');
            return false;
        }
        if(!formdata.securityPrice) {
            showNotification('Security price is required!','warning');
            return false;
        }

        return true;
    }

    const onFormSubmit = (stdata, e) => {

        if(validateForm(stdata)) {

            stdata.portfolioId = selectedPortfolioId;


            if(transactionDate) {
                stdata.transactionDate = transactionDate;
            }
    
    
            const randomNum = getRandomNumber(1000);
    
            console.log('Saving security trade form values : ', stdata);
    
            axios({
                method: 'POST',
                url: config.backendUrl + '/stock/trade',
                data: stdata,
                headers: {"Authorization" : `Bearer ${tokenStr}`}
            }).then((result) => {
                console.log('Save result : ', result);
                if (result.status === 200) {
                    showNotification('Security trade saved', 'info');
                    
                    resetForm();
                    e.target.reset();
                }
            }).catch((e) => {
                console.error('Exception occurred when saving trade : ',e); 
            })

        }

    }

    return (
        <Container fluid>
            
            <Row>
                <Col md="6">
                    <Card>
                        <Card.Header>
                            <Card.Title as="h4">Add security trade</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Form onSubmit={handleSubmit(onFormSubmit)}>
                                <Row>

                                    <Col className="px-1" md="6">
                                        <Form.Group>
                                            <label>Security name</label>
                                            <Form.Control
                                                placeholder="Security name"
                                                name="securityName"
                                                ref={register}
                                                type="text"
                                            >

                                            </Form.Control>

                                        </Form.Group>
                                    </Col>
                                    <Col className="px-1" md="6">
                                        <Form.Group>
                                            <label>Number of securities bought or sold</label>
                                            <Controller
                                                as={NumberFormat}

                                                name="numberOfSecuritiesBoughtOrSold"
                                                className="input form-control"
                                                control={control}
                                            />


                                        </Form.Group>
                                    </Col>

                                </Row>
                                <Row>
                                <Col className="px-1" md="6">
                                        <Form.Group>
                                            <label>Trade expenses</label>
                                            <Controller
                                                as={NumberFormat}

                                                name="tradeExpenses"
                                                className="input form-control"
                                                control={control}
                                            />

                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="px-1" md="6">
                                        <Form.Group>
                                            <label>Security price</label>
                                            <Controller
                                                as={NumberFormat}

                                                name="securityPrice"
                                                className="input form-control"
                                                control={control}
                                            />

                                        </Form.Group>
                                    </Col>
                                    <Col className="px-1" md="6">
                                        <Form.Group>
                                            <label>Planned security sell price</label>
                                            <Controller
                                                as={NumberFormat}

                                                name="plannedSecuritySellPrice"
                                                className="input form-control"
                                                control={control}
                                            />

                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    

                                    <Col className="px-1" md="6">
                                        <Form.Group>
                                            <label>Stop loss price</label>
                                            <Controller
                                                as={NumberFormat}

                                                name="stopLossSecurityPrice"
                                                className="input form-control"
                                                control={control}
                                            />


                                        </Form.Group>
                                    </Col>
                                    <Col className="px-1" md="2">
                                        <Form.Group>
                                            <label>Is buy</label>
                                            <Form.Check
                                                type="switch"
                                                name="isBuy"
                                                defaultChecked={true}
                                                ref={register}
                                                id="custom-switch"
                                            />


                                        </Form.Group>

                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="px-1" md="6">
                                        <Form.Group>

                                            <PortfolioSelect portfolios={data} valueSelected={onPortfolioSelected}></PortfolioSelect>
                                        </Form.Group>
                                    </Col>
                                    <Col className="px-1" md="4">
                                        <Form.Group>
                                        <label>Transaction date</label>
                                            <DatePicker name="transactionDate" className="input form-control" ref={register} selected={transactionDate} onChange={date => setTransactionDate(date)} dateFormat="dd.MM.yyyy"  />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="px-1" md="12">
                                        <Form.Group>
                                            <label>Notes</label>
                                            <Form.Control
                                                placeholder="Notes"
                                                name="notes"
                                                ref={register}
                                                type="text"
                                            >

                                            </Form.Control>

                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="px-1" md="1">
                                        <Button
                                            className="btn-fill pull-right"
                                            type="submit"
                                            variant="info"
                                        >
                                            Save
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>

                </Col>
            </Row>

        </Container>
    )

}

export default SecurityTradeForm;