import AuthProvider from './authProvider';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import AdminLayout from "layouts/Admin.js";


const AuthWrapper = () => {

    return (
        <BrowserRouter>
        <Switch>
            <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
            <Redirect from="/" to="/admin/dashboard" />
        </Switch>
        </BrowserRouter>
    )
}

export default AuthProvider(AuthWrapper);