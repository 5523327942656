import React, { useState, useEffect, useContext }  from "react";
import {AuthContext} from '../authContext';
import PortfolioSelect from '../components/portfolio/PortfolioSelect';
import PositionList from '../components/Position/PositionList';
import {
  Card,
  Table,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import axios from 'axios';
import { config } from '../config';
import { useHistory } from "react-router-dom";


function Positions() {
  
  const [portfolios, setPortfolios] = useState([]);
  const [portfolioId, setPortfolioId] = useState({});
  const ctx = useContext(AuthContext);
  const history = useHistory();
  let tokenStr;
  
  if (ctx && ctx.token && ctx.token.idToken) {
    tokenStr = ctx.token.idToken.rawIdToken;
  } else {
    history.push('/');
  }

  useEffect(async () => {
    const result = await axios(config.backendUrl + '/portfolio',{headers: {"Authorization" : `Bearer ${tokenStr}`}});
    setPortfolios(result.data);
}, []);

const onPortfolioSelected = (selectedPortfolio) => {

  setPortfolioId({portfolioId: selectedPortfolio});

}

  return (
    <>
     <Container fluid>
       <Row>
       <Col md="2">
        <PortfolioSelect portfolios={portfolios} valueSelected={onPortfolioSelected} selectedValue={portfolioId}></PortfolioSelect>
        </Col>
       </Row>
       <Row>
        <PositionList queryObj={portfolioId}></PositionList>
       </Row>
     </Container>
    </>
  );
}

export default Positions;
