import React, { useContext, useState, useEffect } from "react";
import { config } from "../../config";
import { AuthContext } from "../../authContext";
import { useForm, Controller } from "react-hook-form";
import { getRandomNumber } from "../utils";
import  PortfolioSelect  from "../portfolio/PortfolioSelect";
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Form,
  Row,
  Col,
} from "react-bootstrap";
import NumberFormat from 'react-number-format';

function PortfolioAlertForm({
  onSave,
  onCancel,
  showNotification,
  portfolios,
  selectedAlertParam,
}) {
  const ctx = useContext(AuthContext);
  const tokenStr = ctx.token.idToken.rawIdToken;

  const { handleSubmit, register, reset, control } = useForm();
  const [portfolioId, setPortfolioId] = useState({});
 

  const onPortfolioSelected = (selectedPortfolio) => {
    console.log('Setting portfolio: ', selectedPortfolio)
    setPortfolioId({portfolioId: selectedPortfolio});
  };

  useEffect(async () => {

    console.log('Selected alert param: ', selectedAlertParam)
    reset(selectedAlertParam);
    if(selectedAlertParam && selectedAlertParam.portfolioId) {
      console.log('Setting selected portfolio')
      setPortfolioId({portfolioId: selectedAlertParam.portfolioId});
     
    }

  }, [selectedAlertParam]);

  const onFormSubmit = (formData, e) => {

    
    if((!portfolioId) || ((portfolioId) && (!portfolioId.portfolioId))) {
      
      showNotification('Portfolio must be selected','warning');
    }

    if( ((!formData.minimumProfit) && (!formData.minimumValue)) ||
        ( formData.minimumProfit === 0) && (formData.minimumValue === 0)) {
      showNotification('Either minimum profit or minimum value must be defined','warning');
    }

    const saveData = {
      id: selectedAlertParam.id,
      portfolioId: portfolioId.portfolioId,
      minimumProfit: formData.minimumProfit,
      minimumValue: formData.minimumValue
    }

    console.log('Saving : ', saveData)
    onSave(saveData,onClear);

  };

  const onClear = () => {
   
    
    reset({ id: undefined,
      portfolioId: undefined,
      minimumProfit: 0.0,
      minimumValue: 0.0});
    
    setPortfolioId(undefined);
    onCancel();
}


  return (
    <Container fluid>
      <Row>
        <Card>
          <Card.Header>
            <Card.Title as="h4">Save portfolio price alert</Card.Title>
          </Card.Header>

          <Card.Body>
            <Form onSubmit={handleSubmit(onFormSubmit)}>
              <Row md={1}>
                <Col className="px-1" md="12">
                  <Form.Group>
                    <PortfolioSelect
                      portfolios={portfolios}
                      valueSelected={onPortfolioSelected}
                      selectedValue={portfolioId}
                    ></PortfolioSelect>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col className="px-1" md="12">
                  <Form.Group>
                    <label>Minimum portfolio profit</label>
                    <Controller
                      as={NumberFormat}
                      defaultValue={selectedAlertParam.minimumProfit}
                      value={selectedAlertParam.minimumProfit}
                      name="minimumProfit"
                      className="input form-control"
                      control={control}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col className="px-1" md="12">
                  <Form.Group>
                    <label>Minimum portfolio value</label>
                    <Controller
                      as={NumberFormat}
                      defaultValue={selectedAlertParam.minimumValue}
                      value={selectedAlertParam.minimumValue}
                      name="minimumValue"
                      className="input form-control"
                      control={control}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col className="px-1" md="6">
                  <Button
                    className="btn-fill pull-left"
                    type="submit"
                    variant="info"
                  >
                    Save
                  </Button>
                </Col>
                <Col className="px-1" md="6">
                  <Button
                    className="btn-fill pull-right"
                    onClick={onClear}
                    variant="info"
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </Row>
    </Container>
  );
}

export default PortfolioAlertForm;