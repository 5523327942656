import React, { useState, useEffect, useContext } from "react";
import { config } from '../../config';
import {AuthContext} from '../../authContext';
import axios from 'axios';
import {
    Card,
    Table,
    Container,
    Row,
    Col,
} from "react-bootstrap";
import PriceAlertListRow from './PriceAlertListRow';
import ConfirmationModal from '../Common/ConfirmationModal';

function PriceAlertList({needRefresh, onAlertDelete,onEdit}) {

    const [priceAlerts, setPriceAlerts] = useState([]);
    const [modalShow, showModal] = useState(false);
    const [alertToDelete, setAlertToDelete] = useState();
    const ctx = useContext(AuthContext);

    const tokenStr = ctx.token.idToken.rawIdToken;

    useEffect(async () => {
        
        const result = await axios(config.backendUrl + '/alert',{headers: {"Authorization" : `Bearer ${tokenStr}`}});
        setPriceAlerts(result.data);

    }, [needRefresh])

    const onDeleteCancel = () => {

        showModal(false);
    }

    const onDeleteConfirmed = () => {

        showModal(false);
        onAlertDelete(alertToDelete);

    }

    const showDeleteModal = (alert) => {

        setAlertToDelete(alert);
        showModal(true);

    }

    const onAlertEdit = (alert) => {
        onEdit(alert);
    }

    return(
        <Container fluid>
            <Row>
                <Col md="8">
                <Card className="strpied-tabled-with-hover">
                <Card.Header>
                            <Card.Title as="h4">Price alerts</Card.Title>
                            <p className="card-category">
                                Here is a list of your price alerts
                            </p>
                        </Card.Header>
                        <Card.Body className="table-full-width table-responsive px-0">
                        <Table className="table-hover table-striped">
                                <thead>
                                    <tr>
                                        <th className="border-0">Security name</th>
                                        <th className="border-0">Lowest security price</th>
                                        <th className="border-0">Highest security price</th>
                                        <th className="border-0">Profit or loss min</th>
                                        

                                    </tr>
                                </thead>
                                <tbody>
                                    {priceAlerts &&
                                        priceAlerts.map(item => (
                                            <PriceAlertListRow key={item.id} priceAlert={item} onRowDelete={showDeleteModal} onRowEdit={onAlertEdit}></PriceAlertListRow>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <ConfirmationModal modalShow={modalShow} confirmationTitle="Are you sure ?" body="Do you want delete the alert ?" cancel={onDeleteCancel} confirm={onDeleteConfirmed}></ConfirmationModal> 
        </Container>
    )

}

export default PriceAlertList;

