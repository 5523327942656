import React, { useContext, useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import NotificationAlert from "react-notification-alert";
import { useForm, Controller } from "react-hook-form";
import axios from 'axios';
import { config } from '../../../config';
import { AuthContext } from '../../../authContext';
import {
    Badge,
    Button,
    Card,
    Form,
    Navbar,
    Nav,
    Container,
    Row,
    Col,
} from "react-bootstrap";
import NumberFormat from 'react-number-format';

function RealizedProfitLossUpdForm({ modalShow, realizedProfitLoss, cancel }) {


    const ctx = useContext(AuthContext);

    const tokenStr = ctx.token.idToken.rawIdToken;

    

    const notificationAlertRef = React.useRef(null);

    console.log('Given realized profit or loss: ', realizedProfitLoss);

    const [selectedRPL, setSelectedRPL] = useState({

        numberOfSecuritiesSold: realizedProfitLoss.numberOfSecuritiesSold,
        securityBuyPrice: realizedProfitLoss.securityBuyPrice,
        securitySellPrice: realizedProfitLoss.securitySellPrice,
        sellAmountTotal: realizedProfitLoss.sellAmountTotal,
        notes: realizedProfitLoss.notes,
        realizedProfitOrLoss: realizedProfitLoss.realizedProfitOrLoss

    });
    

    const showNotification = (message, type) => {

        let options = {};
        options = {
            place: 'tc',
            message: (
                <div>
                    <div>
                        {message}
                    </div>
                </div>
            ),
            type: type,
            icon: "nc-icon nc-bell-55",
            autoDismiss: 7,
        };
        notificationAlertRef.current.notificationAlert(options);

    }

    const { handleSubmit, register, reset, control } = useForm({ defaultValues: realizedProfitLoss });

    const updateRealizedProfitOrLoss = (formData, e) => {

        try {
            
            console.log('Form data :', formData);

            const rpl = {};

            Object.assign(rpl, realizedProfitLoss);

            rpl.numberOfSecuritiesSold = parseInt(formData.numberOfSecuritiesSold);
            rpl.securityBuyPrice = parseFloat(formData.securityBuyPrice);
            rpl.securitySellPrice = parseFloat(formData.securitySellPrice);
            rpl.sellAmountTotal = parseFloat(formData.sellAmountTotal);
            rpl.realizedProfitOrLoss = parseFloat(formData.realizedProfitOrLoss);
            rpl.notes = formData.notes;

            console.log('Saving rpl: ', rpl);

            const url = config.backendUrl +'/stock/trade/profitsorlosses/update';

            axios({
                method: 'POST',
                url: url,
                data: rpl,
                headers: { "Authorization": `Bearer ${tokenStr}` }
            }).then((result) => {
                console.log('Save result : ', result);
                if (result.status == 200) {
                    showNotification('Realized profit or loss updated', 'info');
                    cancel();
                }
            })


        } catch (e) {
            console.log('Exception occurred : ',e);
            
            showNotification('Exception occurred : '+ e.message,'warning');
        }

    }

    return (
        <>
            <div className="rna-container">
                <NotificationAlert ref={notificationAlertRef} />
            </div>

            <Modal show={modalShow} onHide={() => cancel()}>
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        <b>Update realized profit or loss</b>
                    </Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmit(updateRealizedProfitOrLoss)}>
                    <Modal.Body>
                        <Row>
                            <Col className="px-1" md="6">
                                <Form.Group>
                                    <label>Number of securities sold</label>
                                    <Controller
                                        as={NumberFormat}
                                        defaultValue={realizedProfitLoss.numberOfSecuritiesSold}
                                        value={selectedRPL.numberOfSecuritiesSold}
                                        name="numberOfSecuritiesSold"
                                        className="input form-control"
                                        control={control}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="px-1" md="6">
                                <Form.Group>
                                    <label>Security buy price</label>
                                    <Controller
                                        as={NumberFormat}
                                        defaultValue={realizedProfitLoss.securityBuyPrice}
                                        value={selectedRPL.securityBuyPrice}
                                        name="securityBuyPrice"
                                        className="input form-control"
                                        control={control}
                                    />


                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="px-1" md="6">
                                <Form.Group>
                                    <label>Security sell price</label>
                                    <Controller
                                        as={NumberFormat}
                                        defaultValue={realizedProfitLoss.securitySellPrice}
                                        value={selectedRPL.securitySellPrice}
                                        name="securitySellPrice"
                                        className="input form-control"
                                        control={control}
                                    />


                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="px-1" md="6">
                                <Form.Group>
                                    <label>Security amount total</label>
                                    <Controller
                                        as={NumberFormat}
                                        defaultValue={realizedProfitLoss.sellAmountTotal}
                                        value={selectedRPL.sellAmountTotal}
                                        name="sellAmountTotal"
                                        className="input form-control"
                                        control={control}
                                    />


                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="px-1" md="6">
                                <Form.Group>
                                    <label>Realized profit or loss total</label>
                                    <Controller
                                        as={NumberFormat}
                                        defaultValue={realizedProfitLoss.realizedProfitOrLoss}
                                        value={selectedRPL.realizedProfitOrLoss}
                                        name="realizedProfitOrLoss"
                                        className="input form-control"
                                        control={control}
                                    />


                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="px-1" md="6">
                                <Form.Group>
                                    <label>Notes</label>
                                    <Form.Control
                                        defaultValue={realizedProfitLoss.notes}
                                        placeholder="notes"
                                        name="notes"
                                        ref={register()}
                                        type="text"
                                    ></Form.Control>

                                </Form.Group>
                            </Col>
                        </Row>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={cancel}>Close</Button>
                            <Button variant="danger" type="submit">Update</Button>

                        </Modal.Footer>
                    </Modal.Body>
                </Form>
            </Modal>
        </>
    )

}

export default RealizedProfitLossUpdForm;
