import React from "react";
import Moment from 'react-moment';
import {
    Button,
  
  } from "react-bootstrap";
  

function PositionRow({position, editSellPosition, editPosition, showPositionInfo,showPositionSellModal, 
    showChartModalFunc, showAlertModalFunc, showNewsModalFunc}) {

    const selectEditSellPosition = () => {

        editSellPosition(position);

    }

    const selectEditPosition = () => {
        editPosition(position);
    }

    const showSellModal = () => {
        showPositionSellModal(position);
    }

    const showPositionInfoModal = () => {
        showPositionInfo(position);
    }
    
    const showChartModal = () => {
        showChartModalFunc(position);
    }

    const checkAlert = () => {
        showAlertModalFunc(position);
    }

    const showNewsModal = () => {
        showNewsModalFunc(position);
    }

    return (
        <tr>
            <td>{position.securityName}</td>
            <td>{position.securityCount}</td>
            <td>{position.currentSecurityValue}</td>
            <td>{position.currentSecurityProfitOrLossAmount}</td>
            <td>{position.recommendation}</td>
            <td>{position.analysisTargetPrice}</td>
            <td>{position.singleSecurityValue}</td>
            <td>
            <Moment format="DD.MM.YYYY hh:mm">
                {position.positionUpdateDate}
            </Moment>
            </td>
            <td>
            {
                    position.alert &&
                    <Button type="button" onClick={checkAlert} class="btn btn-warning">
                        <a  class="badge badge-warning" >Alert</a>
                    </Button>
                    
                }
            </td>
            <td>
                {position.externalPosition && 
                <Button variant="link" onClick={selectEditSellPosition} >Sell</Button>
                
                }
                {
                    !position.externalPosition && 
                    <Button variant="link" onClick={showSellModal} >Sell</Button>
                }
                
            <Button variant="link" onClick={showPositionInfoModal} >Details</Button>
            <Button variant="link" onClick={selectEditPosition} >Edit</Button>
            <Button variant="link" onClick={showChartModal}>Chart</Button>
            <Button variant="link" onClick={showNewsModal}>News</Button>
            </td>
          
        </tr>
    )

}

export default PositionRow;
