import React, { useContext, useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import NotificationAlert from "react-notification-alert";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import { config } from "../../../config";
import { AuthContext } from "../../../authContext";
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import NumberFormat from "react-number-format";

function PositionSellForm({ modalShow, position, cancel , reloadTable }) {
  const ctx = useContext(AuthContext);

  const tokenStr = ctx.token.idToken.rawIdToken;

  const notificationAlertRef = React.useRef(null);

  const showNotification = (message, type) => {
    let options = {};
    options = {
      place: "tc",
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: type,
      icon: "nc-icon nc-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const [selectedExtPosition, setSelectedExtPosition] = useState({
    numberOfSecuritiesSold: position.securityCount,
    securityPrice: 0,
    securityName: position.securityName,
    tradeExpenses: 0.0,
    positionId: position.id,
    notes: "",
  });
  const { handleSubmit, register, reset, control } = useForm({
    defaultValues: position,
  });

  const sellPosition = (formData, e) => {

    if(formData.numberOfSecuritiesSold && formData.securityPrice > 0) {
      formData.positionId = position.id;
      formData.securityName = position.securityName;
      formData.portfolioId = position.portfolioId;
      console.log('form data : ', formData);
      const url = config.backendUrl +'/stock/trade/position/sell';

      
      axios({
          method: 'PUT',
          url: url,
          data: formData,
          headers: { "Authorization": `Bearer ${tokenStr}` }
      }).then((result) => {
          console.log('Save result : ', result);
          if (result.status == 200) {
              showNotification('Position sell saved', 'info');
              reloadTable();
              cancel();
          }
      })
    }  else {
      showNotification('Check sell parameters!','warning')
    }
    
   

  };

  return (
    <>
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
        <Modal show={modalShow} onHide={() => cancel()}>
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">
              <b>Sell external position</b>
            </Modal.Title>
          </Modal.Header>
          <Form onSubmit={handleSubmit(sellPosition)}>
            <Modal.Body>
              <Row>
                <Col className="px-1" md="6">
                  <Form.Group>
                    <label>Sold security count</label>
                    <Controller
                      as={NumberFormat}
                      defaultValue={selectedExtPosition.numberOfSecuritiesSold}
                      value={selectedExtPosition.numberOfSecuritiesSold}
                      name="numberOfSecuritiesSold"
                      className="input form-control"
                      control={control}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col className="px-1" md="6">
                  <Form.Group>
                    <label>Trade expenses</label>
                    <Controller
                      as={NumberFormat}
                      defaultValue={selectedExtPosition.tradeExpenses}
                      value={selectedExtPosition.tradeExpenses}
                      name="tradeExpenses"
                      className="input form-control"
                      control={control}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col className="px-1" md="6">
                  <Form.Group>
                    <label>Security sell price</label>
                    <Controller
                      as={NumberFormat}
                      defaultValue={selectedExtPosition.securityPrice}
                      value={selectedExtPosition.securityPrice}
                      name="securityPrice"
                      className="input form-control"
                      control={control}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col className="px-1" md="8">
                  <Form.Group>
                    <label>Notes</label>
                    <Form.Control
                      defaultValue={selectedExtPosition.notes}
                      placeholder="notes"
                      name="notes"
                      ref={register()}
                      type="text"
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
                        <Button variant="secondary" onClick={cancel}>Close</Button>
                    <Button variant="danger" type="submit">Sell</Button>
                       
                </Modal.Footer> 
          </Form>
        </Modal>
      </div>
    </>
  );
}

export default PositionSellForm;