/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard.js";
import Deposit from "views/Deposits";
import Portfolios from "views/Portfolios.js";
import SecurityTrade from "views/SecurityTrade.js";
import Icons from "views/Icons.js";
import Positions from "views/Positions.js";
import Notifications from "views/Notifications.js";
import PriceAlerts from "views/PriceAlerts";
import ProfitOrLossView from 'views/ProfitOrLoss';
import PortfolioAlerts from 'views/PortfolioAlert';
import NotesView from 'views/Notes';
import SettingsView from 'views/Settings';

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-chart-pie-35",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/user",
    name: "Deposits",
    icon: "nc-icon nc-bank",
    component: Deposit,
    layout: "/admin",
  },
  {
    path: "/table",
    name: "Portfolios",
    icon: "nc-icon nc-bullet-list-67",
    component: Portfolios,
    layout: "/admin",
  },
  {
    path: "/typography",
    name: "Security trades",
    icon: "nc-icon nc-paper-2",
    component: SecurityTrade,
    layout: "/admin",
  },
  {
    path: "/positions",
    name: "Positions",
    icon: "nc-icon nc-pin-3",
    component: Positions,
    layout: "/admin",
  },
  {
    path: "/alerts",
    name: "Price alerts",
    icon: "nc-icon nc-time-alarm",
    component: PriceAlerts,
    layout: "/admin",
  },
  {
    path: "/portfolioalert",
    name: "Portfolio alerts",
    icon: "nc-icon nc-time-alarm",
    component: PortfolioAlerts,
    layout: "/admin",
  },
  {
    path: "/profitorloss",
    name: "Profits or losses",
    icon: "nc-icon nc-spaceship",
    component: ProfitOrLossView,
    layout: "/admin",
  },
  {
    path: "/setting",
    name: "Settings",
    icon: "nc-icon nc-settings-gear-64",
    component: SettingsView,
    layout: "/admin",
  },
  {
    path: "/notes",
    name: "Notes",
    icon: "nc-icon nc-notes",
    component: NotesView,
    layout: "/admin",
  }
];

export default dashboardRoutes;
