import React, { useState, useEffect } from "react";

import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Form,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import NumberFormat from 'react-number-format';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import NoteAttachment from './NoteAttachment';


// Rich editor tutorial
//https://blog.logrocket.com/building-rich-text-editors-in-react-using-draft-js-and-react-draft-wysiwyg/

function NoteForm({ note, noteSave, cancelEdit, uploadFileFunc, attachments, removeAttachment }) {
  const [editorState, setEditorState] = useState();
  const { handleSubmit, register, reset, control } = useForm();

  const [file, setFile] = useState();
  const [fileName, setFileName] = useState();

  useEffect(() => {
    setEditorState(note.securityNotes);
    reset(note);
  },[note]);
  
  const saveFile = (e) => {

    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  }

  const uploadFile = async (e) => {

    const formData = new FormData();
    formData.append("formFile", file);
    formData.append("fileName", fileName);

    uploadFileFunc(formData, note);
  }

  const onFormSubmit = (formData, e) => {
 
    if(note && note.id) {
      formData.id = note.id;
    }
    formData.securityNotes = editorState;
    
    noteSave(formData, note);

  };

  return (
    <>
      <Container fluid>
        <Row>
          <Card>
            <Card.Header>
              <Card.Title as="h4">Edit note</Card.Title>
            </Card.Header>
            <Card.Body>
              <Form onSubmit={handleSubmit(onFormSubmit)}>
                <Row>
                  <Col className="px-1" md="6">
                    <Form.Group>
                      <label>Security name</label>
                      <Form.Control
                        placeholder="Security name"
                        name="securityName"
                        ref={register}
                        type="text"
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col className="px-1" md="3">
                    <Form.Group>
                      <label>High price</label>
                      <Controller
                        as={NumberFormat}
                        name="highSecurityAlertPrice"
                        className="input form-control"
                        control={control}
                      />
                    </Form.Group>
                  </Col>
                  <Col className="px-1" md="3">
                    <Form.Group>
                      <label>Low price</label>
                      <Controller
                        as={NumberFormat}
                        name="lowSecurityAlertPrice"
                        className="input form-control"
                        control={control}
                      />
                    </Form.Group>
                  </Col>
                  <Col className="px-1" md="3">
                  <Form.Group>
                      <label>Create alert</label>
                      <Form.Control
                        placeholder="Create alert"
                        name="createAlert"
                        className="input form-control"
                        ref={register}
                        type="checkbox"
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                  <ReactQuill theme="snow" value={editorState} onChange={setEditorState}/>
                  </Col>
                </Row>
                <Row>
                  <Col className="px-6" md="3">
                    <Button
                      className="btn-fill pull-right"
                      type="submit"
                      variant="info"
                    >
                      Save
                    </Button>
                  </Col>
                  <Col className="px-6" md="3">
                    <Button
                      className="btn-fill pull-left"
                      onClick={cancelEdit}
                      variant="info"
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
                {note && note.id &&
                <>
                  <Row>
                     <Col className="px-6" md="3">
                        <input className="btn-fill pull-right" type="file" onChange={saveFile}/>
                      
                     </Col>
                    
                  </Row>
                  <Row>
                    <Col className="px-6" md="3">
                    <input className="btn-fill pull-left" type="button" value="upload" onClick={uploadFile}/>
                    </Col>
                  </Row>
                  </>
                }
              </Form>
            </Card.Body>
            {note && note.id && attachments && attachments.map(attachment => 
          <NoteAttachment attachment={attachment} removeAttachmentFunc={removeAttachment}/>
        )
          
        }
          </Card>
        </Row>
       
      </Container>
    </>
  );
}

export default NoteForm;
