import React, { useContext, useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
import { useForm, Controller } from "react-hook-form";
import { config } from '../../../config';
import { AuthContext } from '../../../authContext';
import {
    Badge,
    Button,
    Card,
    Form,
    Navbar,
    Nav,
    Container,
    Row,
    Col,
} from "react-bootstrap";
import NumberFormat from 'react-number-format';

function ExtPositionSellForm({ modalShow, position, cancel, reloadList }) {

    const ctx = useContext(AuthContext);

    const tokenStr = ctx.token.idToken.rawIdToken;

    const notificationAlertRef = React.useRef(null);

    console.log('Position : ',position);

    const validateForm = (formdata) => {
        console.log('Validating form with data: ', formdata);
        if(!position.id) {
            showNotification('Position must be selected!', 'warning');
            return false;
        }
        if(!formdata.securityCount || formdata.securityCount === 0) {
            showNotification('You must specify how many securities you have sold!','warning');
            return false;
        }
        if((Number(formdata.realizedSecurityProfitOrLoss) === 0 && Number(formdata.securitySellPrice) === 0) ) {
                
            showNotification('Realized profit or loss or sell price must be specified!','warning');
            return false;
        }
        if((Number(formdata.sellAmountTotal) === 0 && Number(formdata.securitySellPrice) === 0) ) {
            showNotification('Total sell amount or sell price must be specified!','warning');
            return false;
        }
        return true;
    }

    const showNotification = (message, type) => {

        let options = {};
        options = {
            place: 'tc',
            message: (
                <div>
                    <div>
                        {message}
                    </div>
                </div>
            ),
            type: type,
            icon: "nc-icon nc-bell-55",
            autoDismiss: 7,
        };
        notificationAlertRef.current.notificationAlert(options);

    }

    const [selectedExtPosition, setSelectedExtPosition] = useState({
        securityCount: position.securityCount,
        securityBuyPrice: 0,
        securitySellPrice: 0,
        realizedSecurityProfitOrLoss: 0.0,
        sellAmountTotal: 0,
        notes: ''
    })
    const { handleSubmit, register, reset, control } = useForm({ defaultValues: position });

    const sellExternalPosition = (formData, e) => {

        formData.id = position.id;

        if(validateForm(formData)) {
            console.log('Position sell:', formData);

            const url = config.backendUrl +'/stock/trade/ext/position/sell';
    
            axios({
                method: 'POST',
                url: url,
                data: formData,
                headers: { "Authorization": `Bearer ${tokenStr}` }
            }).then((result) => {
                console.log('Save result : ', result);
                if (result.status == 200) {
                    showNotification('External position sell saved', 'info');
                    reloadList();
                    cancel();
                }
            })
        }

    }

    return (
        <>
         <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
            <Modal show={modalShow} onHide={() => cancel()}>
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        <b>Sell external position</b>
                    </Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmit(sellExternalPosition)}>
                <Modal.Body>
                    
                        <Row>
                            <Col className="px-1" md="6">
                                <Form.Group>
                                    <label>Sold security count</label>
                                    <Controller
                                        as={NumberFormat}
                                        defaultValue={selectedExtPosition.securityCount}
                                        value={selectedExtPosition.securityCount}
                                        name="securityCount"
                                        className="input form-control"
                                        control={control}
                                    />


                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="px-1" md="6">
                                <Form.Group>
                                    <label>Security buy price</label>
                                    <Controller
                                        as={NumberFormat}
                                        defaultValue={selectedExtPosition.securityBuyPrice}
                                        value={selectedExtPosition.securityBuyPrice}
                                        name="securityBuyPrice"
                                        className="input form-control"
                                        control={control}
                                    />


                                </Form.Group>
                            </Col>
                            <Col className="px-1" md="6">
                                <Form.Group>
                                    <label>Security sell price</label>
                                    <Controller
                                        as={NumberFormat}
                                        defaultValue={selectedExtPosition.securitySellPrice}
                                        value={selectedExtPosition.securitySellPrice}
                                        name="securitySellPrice"
                                        className="input form-control"
                                        control={control}
                                    />


                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="px-1" md="6">
                                <Form.Group>
                                    <label>Realized profit or loss</label>
                                    <Controller
                                        as={NumberFormat}
                                        defaultValue={selectedExtPosition.realizedSecurityProfitOrLoss}
                                        value={selectedExtPosition.realizedSecurityProfitOrLoss}
                                        name="realizedSecurityProfitOrLoss"
                                        className="input form-control"
                                        control={control}
                                    />


                                </Form.Group>
                            </Col>
                            <Col className="px-1" md="6">
                                <Form.Group>
                                    <label>Total amount</label>
                                    <Controller
                                        as={NumberFormat}
                                        defaultValue={selectedExtPosition.sellAmountTotal}
                                        value={selectedExtPosition.sellAmountTotal}
                                        name="sellAmountTotal"
                                        className="input form-control"
                                        control={control}
                                    />


                                </Form.Group>
                            </Col>

                        </Row>
                        <Row>
                            <Col className="px-1" md="8">
                                <Form.Group>
                                    <label>Notes</label>
                                    <Form.Control
                                        defaultValue={selectedExtPosition.notes}
                                        placeholder="notes"
                                        name="notes"
                                        ref={register()}
                                        type="text"
                                    ></Form.Control>

                                </Form.Group>
                            </Col>
                        </Row>
                        <Modal.Footer>
                        <Button variant="secondary" onClick={cancel}>Close</Button>
                    <Button variant="danger" type="submit">Sell</Button>
                       
                </Modal.Footer>
                </Modal.Body>
                    </Form>
               
                
            </Modal>
        </>
    )

}

export default ExtPositionSellForm;