import React, { useState } from "react";
import {
    Card,
    Form,
    Container,
    Button,
    Row,
    Col,
} from "react-bootstrap";
import DatePicker from "react-datepicker";


import "react-datepicker/dist/react-datepicker.css";

function DateRangeSearch({ onSearch }) {

    const [fromDate, setFromDate] = useState(new Date());

    const [toDate, setToDate] = useState(new Date());

    const onSearchButtonPressed = () => {

        const searchObj = {
            from : fromDate,
            to: toDate
        }

        onSearch(searchObj);

    }

    return (
        <>
            <Container fluid>
                <Row>
                    <Col md="10">
                        <Card>
                            <Card.Header>
                                <Card.Title as="h4">Search trades between dates</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col className="px-1" md="3">
                                        <Form.Group>
                                            <label>From date</label>
                                            <DatePicker className="input form-control" dateFormat="dd.MM.yyyy" selected={fromDate} onChange={date => setFromDate(date)} />

                                        </Form.Group>
                                    </Col>
                                    <Col className="px-1" md="3">
                                        <Form.Group>
                                            <label>To date</label>
                                            <DatePicker className="input form-control" dateFormat="dd.MM.yyyy" selected={toDate} onChange={date => setToDate(date)} />

                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="px-1" md="1">
                                        <Button
                                            className="btn-fill pull-right"
                                            onClick={onSearchButtonPressed}
                                            variant="info"
                                        >
                                            Search
                                        </Button>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )

}

export default DateRangeSearch;