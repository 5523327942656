import React from "react";
import { Button } from "react-bootstrap";


function SettingRow({settingRow, editSettingFunc, removeSettingFunc}) {

    const removeSetting = () => {
        removeSettingFunc(settingRow);
    }

    const editSetting = () => {
        editSettingFunc(settingRow);
    }

    const getSettingValueTd = () => {
        if(settingRow.settingType === "BOOL") {
            return <td> <input type="checkbox" checked={settingRow.settingValueBool} disabled/></td>
        } else if (settingRow.settingType === "DOUBLE") {
            return <td>{settingRow.settingValueDouble}</td>
        } else if(settingRow.settingType === "STRING") {
          return   <td>{settingRow.settingValue}</td>
        }
    }

    return(
        <tr>
            <td>{settingRow.settingName}</td>
            {getSettingValueTd()}
            <td><Button variant="link" onClick={editSetting}>Edit</Button></td>
            <td><Button variant="danger" onClick={removeSetting}>Remove</Button></td>
        </tr>
    );
}

export default SettingRow;