import React from "react";
import {
  Button,

} from "react-bootstrap";
import Moment from 'react-moment';

function DepositListRow({deposit, onRowSelected}) {


    const rowSelected = (event) => {
      
        onRowSelected(portfolio);
  
      }
  
      return (<tr>
          <td>{deposit.depositAmount}</td>
          <td><Moment format="DD.MM.YYYY hh:mm">
              {deposit.depositDate}
            </Moment></td>
          <td>{deposit.portfolioName}</td>
          
          
        </tr>)

}

export default DepositListRow;