import React, { useState, useEffect, useContext } from "react";
import { config } from '../../config';
import {AuthContext} from '../../authContext';
import RealizedProfitOrLossRow from './RealizedProfitOrLossRow';
import DateRangeSearch from '../Common/DateRangeSearch';
import axios from 'axios';
import {
    Card,
    Table,
    Container,
    Row,
    Col,
} from "react-bootstrap";

import RealizedProfitLossUpdForm from './form/RealizedProfitLossUpdForm';

function RealizedProfitOrLossList() {
    
    const [profitOrLosses, setProfitOrLosses] = useState([]);
    const [queryObj,setQueryObj] = useState();
    const [showEditModal,setShowEditModal] = useState();
    const [selectedRpl, setSelectedRpl] = useState({});

    const ctx = useContext(AuthContext);
    const tokenStr = ctx.token.idToken.rawIdToken;



    useEffect(async() => {

        axios({
            method: 'POST',
            url: config.backendUrl + '/stock/trade/profitsorlosses',
            data: queryObj,
            headers: {"Authorization" : `Bearer ${tokenStr}`}
        }).then((result) => {
            console.log('Save result : ', result);
            if (result.status == 200) {
                setProfitOrLosses(result.data);
            }
        }).catch((e) => {
            console.error('Exception occurred when querying profit or losses: ',e); 
        })

    },[queryObj]);

    const showUpdateModal = (rpl) => {
        console.log('List RPL:', rpl);
        setSelectedRpl(rpl);
        setShowEditModal(true);
    }

    const cancelUpdateModal = () => {

        setSelectedRpl({});
        setShowEditModal(false);

    }

    return (
        <Container fluid>
            <Row>
                <DateRangeSearch onSearch={setQueryObj}></DateRangeSearch>
            </Row>
              <Row>
                    <Col md="8">
                        <Card className="strpied-tabled-with-hover">
                        <Card.Header>
                            <Card.Title as="h4">Realized profit or losses</Card.Title>

                            </Card.Header>
                            <Card.Body className="table-full-width table-responsive px-0">
                            <Table className="table-hover table-striped">
                                <thead>
                                    <tr>
                                        <th className="border-0">Security name</th>
                                        <th className="border-0">Number of securities sold</th>
                                        <th className="border-0">Buy price</th>
                                        <th className="border-0">Sell price</th>
                                        <th className="border-0">Total sell amount</th>
                                        <th className="border-0">Realized profit or loss</th>
                                        <th className="border-0">Portfolio name</th>
                                        <th className="border-0">Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {profitOrLosses &&
                                        profitOrLosses.map(item => (
                                            <RealizedProfitOrLossRow key={item.id} realizedProfitOrLoss={item} rowSelected={showUpdateModal}></RealizedProfitOrLossRow>
                                        ))
                                    }
                                </tbody>
                            </Table>
                            </Card.Body>
                        </Card>
                    </Col>
              </Row>
              <RealizedProfitLossUpdForm modalShow={showEditModal} realizedProfitLoss={selectedRpl} cancel={cancelUpdateModal}></RealizedProfitLossUpdForm>
        </Container>
    )
}

export default RealizedProfitOrLossList;