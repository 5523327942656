import Modal from 'react-bootstrap/Modal';
import {
    Button,
} from "react-bootstrap";

function ConfirmationModal({modalShow ,confirmationTitle, body, cancel, confirm }) {


    return (
        <>
            <Modal show={modalShow} onHide={() => cancel()}>
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        <b>{confirmationTitle}</b>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>{body}</h5>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={cancel}>Close</Button>
                    <Button variant="danger" onClick={confirm}>Confirm</Button>
                </Modal.Footer>
            </Modal>
        </>
    )

}

export default ConfirmationModal;