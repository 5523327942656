import React,{useEffect, useState, useContext} from "react";
import { config } from '../config';
import SecurityTradeForm from '../components/SecurityTrade/SecurityTradeForm';
import SecurityTradeList from '../components/SecurityTrade/SecurityTradeList';
import SecuritySearch from '../components/SecurityTrade/SecuritySearch';
import SecurityTradeDetails from '../components/SecurityTrade/SecurityTradeDetails';
import NotificationAlert from "react-notification-alert";
import {AuthContext} from '../authContext';
import { useHistory } from "react-router-dom";
import axios from "axios";

function SecurityTrade() {

  const [searchVal, onSearchObjSubmitted] = useState({});
  const [selectedRow, rowSelected] = useState({});
  const [modalShow, showModal] = useState(false);
  const notificationAlertRef = React.useRef(null);

  const ctx = useContext(AuthContext);
  const history = useHistory();
  let tokenStr;
  
  if (ctx && ctx.token && ctx.token.idToken) {
    tokenStr = ctx.token.idToken.rawIdToken;
  } else {
    history.push('/');
  }

  const resetSelectedValue = () => {
    const emptyTrade = {
      isBuy: true,
      notes: null,
      numberOfSecuritiesBoughtOrSold: 0,
      plannedSecuritySellPrice: 0,
      portfolioId: null,
      securityName: null,
      securityPrice: 0,
      stopLossSecurityPrice: 0
    }

  }

  const showNotification = (message, type) => {

    let options = {};
    options = {
        place: 'tc',
        message: (
            <div>
                <div>
                    {message}
                </div>
            </div>
        ),
        type: type,
        icon: "nc-icon nc-bell-55",
        autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);

}

  const onValueSelected = (value) => {
      rowSelected(value);
      showModal(true);
   
  }

  const onTradeDelete = (trade) => {
    
    console.log('On trade delete : ', trade);
    const result =  axios({
      method: 'DELETE',
      url: config.backendUrl + '/stock/trade/'+trade.id,
      headers: {"Authorization" : `Bearer ${tokenStr}`}
    })
    result.then(() => {
      console.log('Search val : ', searchVal);

      onSearchObjSubmitted({...searchVal, id:trade.id});
      showNotification('Security trade removed', 'info');
    })
    
  }

  const onSearchInitiated = (searchOjb) => {

    console.log('Search obj : ',searchOjb)
    onSearchObjSubmitted(searchOjb)

  }

 

  return (
    <>
    <div className="rna-container">
                <NotificationAlert ref={notificationAlertRef} />
    </div>
    <SecurityTradeForm selectedPortfolio={selectedRow} resetForm={resetSelectedValue} showNotification={showNotification}></SecurityTradeForm>
    <SecuritySearch onSearch={onSearchInitiated}></SecuritySearch>
    <SecurityTradeList queryObj={searchVal} onTradeSelected={onValueSelected} onTradeDelete={onTradeDelete}></SecurityTradeList>
    <SecurityTradeDetails securityTrade={selectedRow} modalShow={modalShow} setShowModal={showModal}></SecurityTradeDetails>
    
    </>
  );
}

export default SecurityTrade;
