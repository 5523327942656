import React, { useState, useEffect } from "react";
import { config } from '../../config';
import Modal from 'react-bootstrap/Modal';
import {
    Row,
    Col,
} from "react-bootstrap";
import Moment from 'react-moment';

function SecurityTradeDetails({ securityTrade, modalShow, setShowModal }) {


    const checkTradeType = (trade) => {

        if (trade.isBuy) {
            return "BUY";
        } else {
            return "SELL";
        }

    }



    return (
        <>
            <Modal
                size="lg"
                show={modalShow}
                onHide={() => setShowModal(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        <b>Security trade details</b>
          </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col className="px-1" md="4">
                            <label><b>Security name</b></label>
                            <h5>{securityTrade.securityName}</h5>
                        </Col>
                        <Col className="px-1" md="4">
                            <label><b>Number of bought or sold</b></label>
                            <h5>{securityTrade.numberOfSecuritiesBoughtOrSold}</h5>
                        </Col>

                    </Row>
                    <Row>
                        <Col className="px-1" md="4">
                            <label><b>Current security count</b></label>
                            <h5>{securityTrade.currentNumberOfSecurities}</h5>
                        </Col>
                        <Col className="px-1" md="4">
                            <label><b>Transaction date</b></label>
                            <h5> <Moment format="DD.MM.YYYY hh:mm">
                                {securityTrade.transactionDate}
                            </Moment></h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="px-1" md="4">
                            <label><b>Security price</b></label>
                            <h5>{securityTrade.securityPrice}</h5>
                        </Col>
                        <Col className="px-1" md="4">
                            <label><b>Planned sell price</b></label>
                            <h5>{securityTrade.plannedSecuritySellPrice}</h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="px-1" md="4">
                            <label><b>Stop loss security price</b></label>
                            <h5>{securityTrade.stopLossSecurityPrice}</h5>
                        </Col>
                        <Col className="px-1" md="4">
                            <label><b>Trade type</b></label>
                            <h5>{checkTradeType(securityTrade)}</h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="px-1" md="8">
                            <label><b>Notes</b></label>
                            <h5>{securityTrade.notes}</h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="px-1" md="4">
                            <label><b>Last update</b></label>
                            <h5> <Moment format="DD.MM.YYYY hh:mm">
                                {securityTrade.updateDate}
                            </Moment></h5>
                        </Col>
                    </Row>
                </Modal.Body>

            </Modal>
        </>
    )


}

export default SecurityTradeDetails;