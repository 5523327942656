import React from "react";
import Moment from 'react-moment';
import {
    Button,
  
  } from "react-bootstrap";

function RealizedProfitOrLossRow ({realizedProfitOrLoss, rowSelected}) {


    const handleRowSelected = () => {
        console.log('Row RPL:', realizedProfitOrLoss);
        rowSelected(realizedProfitOrLoss);
    }

    return (
        <tr>
            <td>{realizedProfitOrLoss.securityName}</td>
            <td>{realizedProfitOrLoss.numberOfSecuritiesSold}</td>
            <td>{realizedProfitOrLoss.securityBuyPrice}</td>
            <td>{realizedProfitOrLoss.securitySellPrice}</td>
            <td>{realizedProfitOrLoss.sellAmountTotal}</td>
            <td>{realizedProfitOrLoss.realizedProfitOrLoss}</td>
            <td>{realizedProfitOrLoss.portfolioName}</td>
            <td>
            <Moment format="DD.MM.YYYY hh:mm">
                {realizedProfitOrLoss.sellDate}
            </Moment>
            </td>
            <td><Button variant="link" onClick={handleRowSelected}>Select</Button></td>

            
        </tr>

    )


}

export default RealizedProfitOrLossRow;