import React, {useContext} from "react";
import NotificationAlert from "react-notification-alert";
import {config} from '../../config';
import {getRandomNumber} from '../utils';
import {AuthContext} from '../../authContext';
import {
    Badge,
    Button,
    Card,
    Form,
    Navbar,
    Nav,
    Container,
    Row,
    Col,
  } from "react-bootstrap";
  import { useForm } from "react-hook-form";
  import axios from 'axios';

  function PortfolioForm({refreshTable,selectedPortfolio, resetForm}) {

    const { register, handleSubmit, watch, errors } = useForm();
    const notificationAlertRef = React.useRef(null);

    const ctx = useContext(AuthContext);

    const tokenStr = ctx.token.idToken.rawIdToken;

    const showNotification = (message, type) =>  {

      let options = {};
      options = {
        place: 'tc',
        message: (
          <div>
            <div>
              {message}
            </div>
          </div>
        ),
        type: type,
        icon: "nc-icon nc-bell-55",
        autoDismiss: 7,
      };
      notificationAlertRef.current.notificationAlert(options);

    }

    const onDelete = () => {
      
      const deleteUrl = config.backendUrl +'/portfolio/'+selectedPortfolio.id;
      axios({
        method: 'DELETE',
        url: deleteUrl,
        headers: {"Authorization" : `Bearer ${tokenStr}`}
      }).then((res) => {
        if(res.status == 200) {
          showNotification('Portfolio removed','info');
          refreshTable(getRandomNumber(1000));
          resetForm();
          watch();
        }
      })
    }

    const validateForm = (formData) => {

      if(formData.portfolioName || formData.portfolioName === "") {
        showNotification('warning','Portfolio name is required!')
      }

      return true;
    }

    const onFormSubmit = (pdata, e) => {

        if(validateForm(pdata)) {
          if(selectedPortfolio && selectedPortfolio.id) {
            pdata.id = selectedPortfolio.id
          }
          const randomNum = getRandomNumber(1000);
          
          axios({
            method: 'POST',
            url: config.backendUrl + '/portfolio',
            data: pdata,
            headers: {"Authorization" : `Bearer ${tokenStr}`}
          }).then((result) => {
            console.log('Save result : ', result);
            if(result.status == 200) {
              showNotification('Portfolio saved','info');
              refreshTable(randomNum);
              resetForm();
              e.target.reset();
            }
          })
        }

    }

    const cancel = (e) => {

      resetForm();
      watch();

    }

    return ( <Container fluid>
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
          <Row>
          <Col md="8">
          <Card>
              <Card.Header>
                <Card.Title as="h4">Edit portfolio</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form onSubmit={handleSubmit(onFormSubmit)}>
                  <Row>
                  <Col className="px-1" md="4">
                      <Form.Group>
                        <label>Name</label>
                        <Form.Control
                          defaultValue={selectedPortfolio.portfolioName}
                          placeholder="Name"
                          name="portfolioName"
                          ref={register({required: true})}
                          type="text"
                        >
                          
                        </Form.Control>
                        {errors.portfolioName &&   <Badge variant="warning">Portfolio name is required</Badge>}
                      </Form.Group>
                    </Col>
                    <Col className="px-1" md="4">
                      <Form.Group>
                        <label>Description</label>
                        <Form.Control
                          defaultValue={selectedPortfolio.description}
                          placeholder="Description"
                          name="description"
                          ref={register}
                          type="text"
                        ></Form.Control>
                         
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                  <Col className="px-1" md="4">
                      <Form.Group>
                        <label>Portfolio holder</label>
                        <Form.Control
                          defaultValue={selectedPortfolio.portfolioHolder}
                          placeholder="Holder"
                          name="portfolioHolder"
                          ref={register({required: true})}
                          type="text"
                        ></Form.Control>
                         {errors.portfolioHolder && <Badge variant="warning">Portfolio holder is required</Badge>}
                      </Form.Group>
                    </Col>
                    <Col className="px-1" md="4">
                      <Form.Group>
                        <label>Portfolio code</label>
                        <Form.Control
                          defaultValue={selectedPortfolio.portfolioCode}
                          placeholder="Code"
                          name="portfolioCode"
                          ref={register}
                          type="text"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                  <Col className="px-1" md="1">
                  <Button
                    className="btn-fill pull-right"
                    type="submit"
                    variant="info"
                  >
                    Save
                  </Button>
                  </Col>
                  {selectedPortfolio.id && 
                  <div>
                  <Col className="px-1" md="1">
                  <Button
                    className="btn-fill pull-left"
                    onClick={onDelete}
                    variant="warning"
                  >
                    Delete
                  </Button>
                  </Col>
                  <Col className="px-1" md="1">
                  <Button
                    className="btn-fill pull-left"
                    onClick={cancel}
                    variant="secondary"
                  >
                    Cancel
                  </Button>
                  </Col>
                  </div>
                  }
                  </Row>
                </Form>
              </Card.Body>
           </Card>   

          </Col>
          </Row>
    </Container> )
}

export default PortfolioForm;