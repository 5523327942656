import React from "react";
import Modal from "react-bootstrap/Modal";
import Moment from "react-moment";
import NotificationDetails from './NotificationDetails';
import {
  Row,
  Button
} from "react-bootstrap";

function AlertModal({ modalShow, positionAlert, notifications, cancel, acknowledgeFunc }) {

  const ackAlert = () => {
    acknowledgeFunc(positionAlert);
  }

  return (
    <Modal show={modalShow} onHide={() => cancel()}>
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-sm">
          <b>Alert {positionAlert.alertKey}</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
        <Button variant="link" onClick={ackAlert} >Acknowledge</Button>
        </Row>
        <Row>
           <p> <b>Last alert time: </b></p>
         
        </Row>
        <Row>
        <Moment format="DD.MM.YYYY hh:mm">
            {positionAlert.alertTime}
          </Moment>
        </Row>
      
      </Modal.Body>
    </Modal>
  );
}

export default AlertModal;
