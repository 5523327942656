import React from "react";
import { Button } from "react-bootstrap";

function PriceAlertListRow({priceAlert, onRowDelete, onRowEdit}) {

    const onAlertDelete = () => {

        onRowDelete(priceAlert);

    }


    const onEdit = () => {

        onRowEdit(priceAlert);

    }

    return (
        <tr>
            <td>{priceAlert.securityName}</td>
            <td>{priceAlert.lowSecurityPrice}</td>
            <td>{priceAlert.highSecurityPrice}</td>
            <td>{priceAlert.positionProfitOrLossMin}</td>
            <td><Button variant="link" onClick={onEdit}>Edit</Button></td>
            <td><Button variant="danger" onClick={onAlertDelete}>Remove</Button></td>
        </tr>
    )

}

export default PriceAlertListRow;