import React from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

function SimpleLineChart({title,data,yDataKey,xDataKey}) {


   return (
       <div>
        <h4>{title}</h4>
        <LineChart
        width={500}
        height={300}
        title={title}
        data={data}
        margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
        }}
        >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={xDataKey} />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey={yDataKey} stroke="#8884d8" activeDot={{ r: 8 }} />
        
        </LineChart>
        </div>

    )
}

export default SimpleLineChart;