import React, { useState, useEffect, useContext } from "react";
import {
    Badge,
    Button,
    Card,
    Navbar,
    Nav,
    Table,
    Container,
    Row,
    Col,
} from "react-bootstrap";

function NotesListRow({ note, remove, selectNote }) {


    console.log('NotesListRow : ', note);
    const removeNote = () => {

        remove(note);

    }

    const selectNoteToEdit = () => {

        selectNote(note);
    }

    return (<tr>
        <td>{note.securityName}</td>
        <td>{note.securityIsin}</td>
        <td>{note.lowSecurityAlertPrice}</td>
        <td>{note.highSecurityAlertPrice}</td>
        <td><Button variant="danger" onClick={removeNote} className="danger">Remove</Button></td>
        <td><Button onClick={selectNoteToEdit} >Edit</Button></td>
    </tr>)
}

export default NotesListRow;