import React, {useState} from "react";
import {
    Form,
   
  } from "react-bootstrap";

  function SettingSelect({settingSelectValues, selectLabel, valueSelected, selectedVal}) {

    console.log('Selected val : ', selectedVal);

    const onChange = (e) => {

        valueSelected(e.target.value);
    }

    const createOption = (item) => {
      if(item === selectedVal) {
       return  <option key={item} value={item} selected>{item}</option>
      } else {
       return <option key={item} value={item} >{item}</option>
      }
    }

    return (
        <div>
            <Form.Group>
                        <label>{selectLabel}</label>
                        <Form.Control
                            as="select"
                            onChange={onChange}
                        >
                          <option></option>
                          {
                            
                            settingSelectValues &&
                            settingSelectValues.map(item => (createOption(item)))
                          }
                        </Form.Control>
                        
                      </Form.Group>
        </div>
    )

  }

  export default SettingSelect;