import React, {useContext} from "react";
import {AuthContext} from '../authContext';
import DepositForm from '../components/Deposit/DepositForm';
import DepositList from '../components/Deposit/DepositList';
import { useHistory } from "react-router-dom";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
} from "react-bootstrap";

function Deposit() {

  const ctx = useContext(AuthContext);
  const history = useHistory();

  if (ctx && ctx.token && ctx.token.accessToken) {
    
  } else {
    history.push('/');
  }

  return (
    <>
      <Container fluid>
        <DepositForm></DepositForm>
        <DepositList></DepositList>
      </Container>
    </>
  );
}

export default Deposit;
