import React, { useContext, useState,useEffect } from "react";
import NotificationAlert from "react-notification-alert";
import { config } from '../../config';
import { AuthContext } from '../../authContext';
import { useForm, Controller } from "react-hook-form";
import PositionSelect from "../Position/PositionSelect";
import {getRandomNumber} from '../utils';
import axios from 'axios';
import {
    Badge,
    Button,
    Card,
    Form,
    Navbar,
    Nav,
    Container,
    Row,
    Col,
} from "react-bootstrap";
import NumberFormat from 'react-number-format';

function PriceAlertForm({refreshTable, selectedAlertParam, onCancel}) {

    const notificationAlertRef = React.useRef(null);
    const ctx = useContext(AuthContext);
    
    const [selectedPositionName, selectPositionName] = useState();
    const [selectedAlert, setSelectedAlert] = useState(selectedAlertParam);
    const { handleSubmit, register, reset, control } = useForm({defaultValues: selectedAlert});
    const tokenStr = ctx.token.idToken.rawIdToken;

    useEffect(async () => {
        
        reset({values: selectedAlertParam});
        console.log('Selected alert : ', selectedAlertParam);
        if(selectedAlertParam) {
            console.log('Selected alert param: ', selectedAlertParam);
            selectPositionName(selectedAlertParam.securityName);
        setSelectedAlert(selectedAlertParam);
        
       
        }
        
    },[selectedAlertParam]);

    const showNotification = (message, type) => {

        let options = {};
        options = {
            place: 'tc',
            message: (
                <div>
                    <div>
                        {message}
                    </div>
                </div>
            ),
            type: type,
            icon: "nc-icon nc-bell-55",
            autoDismiss: 7,
        };
        notificationAlertRef.current.notificationAlert(options);

    }

    const validateForm = (formdata) => {

        
        if(!selectedPositionName)
        {
            showNotification('Position must be selected!', 'warning');
            return false;
        }
        if(
            ((!formdata.lowSecurityPrice) && (!formdata.highSecurityPrice) && (!formdata.positionProfitOrLossMin)) ||
            ((formdata.lowSecurityPrice === 0) && (formdata.highSecurityPrice === 0) && (formdata.positionProfitOrLossMin === 0))
            
            ){
            showNotification('Either profit, low price, high price must be given!','warning');
            return false;
        }
        if(!formdata.message || (formdata.message && formdata.message.length <1 )) {
            showNotification('Please give notification message','warning');
            return false;
        }

        return true;
    }

    const onFormSubmit = (formData, e) => {

        if(validateForm(formData)) {

            formData.securityName = selectedPositionName;
            console.log('Saving form data: ', formData);
            
            if(selectedAlert && selectedAlert.id) {
                formData.id = selectedAlert.id;
                console.log('Updating value', formData);
                console.log('Selected data :' ,selectedAlert);
                
            }
            
            console.log('Saving price alert : ', formData);
            const randomNum = getRandomNumber(1000);
    
            axios({
                method: 'POST',
                url: config.backendUrl + '/alert',
                data: formData,
                headers: { "Authorization": `Bearer ${tokenStr}` }
            }).then((result) => {
                console.log('Save result : ', result);
                if (result.status == 200) {
                    showNotification('Price alert saved', 'info');
                    refreshTable(randomNum);
                    //resetForm();
                    e.target.reset();
                }
            })

        }

    }

    const positionSelected = (position) => {

        console.log('Position selected : ', position);
        selectPositionName(position);

    }

    const onClear = () => {
        console.log('Clearing form : ',selectedAlert);
        console.log('Clearing form : ',selectedPositionName);
        reset({values: {}});
        onCancel();
    }

    return (
        <Container fluid>
             <div className="rna-container">
                <NotificationAlert ref={notificationAlertRef} />
            </div>
            <Row>
                <Col md="6">
                    <Card>
                        <Card.Header>
                            <Card.Title as="h4">Save price alert</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Form onSubmit={handleSubmit(onFormSubmit)}>
                                <Row>
                                    <Col className="px-1" md="6">
                                        <Form.Group>

                                            <PositionSelect valueSelected={positionSelected} selectedValue={selectedPositionName}></PositionSelect>

                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="px-1" md="6">
                                        <Form.Group>
                                            <label>Lowest security price</label>
                                            <Controller
                                                as={NumberFormat}
                                                defaultValue={selectedAlert.lowSecurityPrice}
                                                value={selectedAlert.lowSecurityPrice}
                                                name="lowSecurityPrice"
                                                className="input form-control"
                                                control={control}
                                            />


                                        </Form.Group>
                                    </Col>
                                    <Col className="px-1" md="6">
                                        <Form.Group>
                                            <label>Highest security price</label>
                                            <Controller
                                                as={NumberFormat}
                                                defaultValue={selectedAlert.highSecurityPrice}    
                                                value={selectedAlert.highSecurityPrice}
                                                name="highSecurityPrice"
                                                className="input form-control"
                                                control={control}
                                            />


                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="px-1" md="6">
                                        <Form.Group>
                                            <label>Position profit or loss </label>
                                            <Controller
                                                as={NumberFormat}
                                                defaultValue={selectedAlert.positionProfitOrLossMin}    
                                                
                                                name="positionProfitOrLossMin"
                                                className="input form-control"
                                                control={control}
                                            />


                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                            <Col className="px-1" md="8">
                                <Form.Group>
                                    <label>Message</label>
                                    <Form.Control
                                        defaultValue={selectedAlert.message}
                                        placeholder="message"
                                        name="message"
                                        ref={register()}
                                        type="text"
                                    ></Form.Control>

                                </Form.Group>
                            </Col>
                        </Row>
                                <Row>
                                    <Col className="px-1" md="2">
                                        <Button
                                            className="btn-fill pull-right"
                                            type="submit"
                                            variant="info"
                                        >
                                            Save
                                        </Button>
                                    </Col>
                                    <Col className="px-1" md="2">
                                        <Button
                                            className="btn-fill pull-right"
                                            onClick={onClear}
                                            variant="info"
                                        >
                                            Cancel
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

        </Container>
    )

}

export default PriceAlertForm;