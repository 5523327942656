import React, {useEffect,useState} from "react";
import {
    Form,
   
  } from "react-bootstrap";

function PortfolioSelect({portfolios, valueSelected, selectedValue}) {

    
    const onChangePortfolio = (e) => {

        
        valueSelected(e.target.value);

    }

    return(
        <div>
             <Form.Group>
                        <label>Portfolio</label>
                        <Form.Control
                            as="select"
                            onChange={onChangePortfolio}
                        >
                          <option></option>
                          {
                            
                              portfolios &&
                              portfolios.map(item => (<option key={item.id} selected={selectedValue && selectedValue.portfolioId === item.id} value={item.id}>{item.portfolioName}</option>))
                          }
                        </Form.Control>
                        
                      </Form.Group>
        </div>
    )    

}

export default PortfolioSelect;