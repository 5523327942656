import React, {useState,useEffect,useContext} from "react";
import {
    Form,
   
  } from "react-bootstrap";
import axios from 'axios';
import {AuthContext} from '../../authContext';
import {config} from '../../config';


function PositionSelect({ valueSelected, selectedValue}) {

    const [positions, setPositions] = useState([]);
    const ctx = useContext(AuthContext);
    const tokenStr = ctx.token.idToken.rawIdToken;

    useEffect(async () => {
        const result = await axios(config.backendUrl + '/stock/trade/position/securities',{headers: {"Authorization" : `Bearer ${tokenStr}`}});
        setPositions(result.data);
    }, []);

    const onChangePosition = (e) => {

        
        valueSelected(e.target.value);

    }

    return(
        <div>
             <Form.Group>
                        <label>Position</label>
                        <Form.Control
                            as="select"
                            onChange={onChangePosition}
                        >
                          <option></option>
                          {
                              positions &&
                              positions.map(item => (<option key={item.id} value={item.securityName} selected={selectedValue && selectedValue === item.securityName}>{item.securityName}</option>))
                          }
                        </Form.Control>
                        
                      </Form.Group>
        </div>
    )    

}

export default PositionSelect;