import React, { useState, useEffect, useContext } from "react";
import { config } from '../../config';
import {AuthContext} from '../../authContext';
import axios from 'axios';
import {
    Card,
   
    Row,
    Col,
} from "react-bootstrap";

function DashBoardCard({portfolioId, portfolioName}) {

    const [positions, setPositions] = useState([]);
    const [positionSummary, setPositionSummary] = useState([]);
    const ctx = useContext(AuthContext);

    const tokenStr = ctx.token.idToken.rawIdToken;

    useEffect(async () => {
        console.log('Portfolio id : ', portfolioId);
        const result = await axios(config.backendUrl + '/stock/trade/position/portfolio/' + portfolioId,{headers: {"Authorization" : `Bearer ${tokenStr}`}});
        console.log('Position result : ', result);
        setPositions(result.data);
        let currentValue = 0.0;
        let profitLoss = 0.0;
        for(let pos of result.data) {
            console.log('Position : ',pos);
            currentValue = currentValue+pos.currentSecurityValue;
            profitLoss = profitLoss+pos.currentSecurityProfitOrLossAmount;
        }

        const summary = {

            currentValue : Number(currentValue).toFixed(2),
            profitLoss : Number(profitLoss).toFixed(2)

        }
        console.log('Position summary : ', summary);
        setPositionSummary(summary);

    }, [portfolioId])

    return(
        <>
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-money-coins text-warning"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">{portfolioName} profit:</p>
                      <Card.Title as="h4">{positionSummary.profitLoss}</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats">
                 
                  Value: {positionSummary.currentValue}
                </div>
              </Card.Footer>
            </Card>
        </>
    )

}

export default DashBoardCard;