import React, { useState, useEffect, useContext } from "react";
import { config } from '../../config';
import axios from 'axios';
import {
    Badge,
    Button,
    Card,
    Navbar,
    Nav,
    Table,
    Container,
    Row,
    Col,
} from "react-bootstrap";
import DepositListRow from './DepositListRow';
import {getRandomNumber} from '../utils';
import {AuthContext} from '../../authContext';

function DepositList({needRefresh}) {

    const [data, setData] = useState([]);
    const ctx = useContext(AuthContext);
    const tokenStr = ctx.token.idToken.rawIdToken;

    let fetchData = needRefresh;

    useEffect(async () => {
        const result = await axios(config.backendUrl +'/portfolio/deposit',{headers: {"Authorization" : `Bearer ${tokenStr}`}});
        console.log('Got data : ',result);
        setData(result.data);
    },[fetchData])

    const rowSelected = (row) => {
        fetchData = getRandomNumber(1000);
    }

    return (
        <Container fluid>
            <Row>
                <Col md="8">
                    <Card className="strpied-tabled-with-hover">

                        <Card.Header>
                            <Card.Title as="h4">Deposits</Card.Title>
                            <p className="card-category">
                                Here is a list of your deposits
                            </p>
                        </Card.Header>
                        <Card.Body className="table-full-width table-responsive px-0">
                            <Table className="table-hover table-striped">
                                <thead>
                                    <tr>
                                        <th className="border-0">Deposit amount</th>
                                        <th className="border-0">Deposit date</th>
                                        <th className="border-0">Portfolio</th>
                                        

                                    </tr>
                                </thead>
                                <tbody>
                                { data &&
                                        data.map(item => (
                                            <DepositListRow key={item.id} deposit={item} onRowSelected={rowSelected}></DepositListRow>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        </Card.Body>

                    </Card>
                </Col>
            </Row>
        </Container>
    )

}

export default DepositList;