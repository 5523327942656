import { UserAgentApplication } from 'msal';
import { config } from './config';

export const requiresInteraction = (errorMessage) => {
    if (!errorMessage || !errorMessage.length) {
      return false;
    }
  
    return (
      errorMessage.indexOf('consent_required') > -1 ||
      errorMessage.indexOf('interaction_required') > -1 ||
      errorMessage.indexOf('login_required') > -1
    );
  };
  

export const AUTH_SCOPES = {
    OPENID: 'openid',
    OFFLINE_ACCESS: 'offline_access',
    PROFILE: 'profile'
  };

  export const AUTH_REQUESTS = {
    LOGIN: {
      scopes: ["api://c81344e0-6406-4cf0-865a-2e0fc72aa75f/Access.api"],
    }
  };
  console.log('Redirect url : ', config.redirectUrl);

  export const msalApp = new UserAgentApplication({
    auth: {
      clientId: 'c81344e0-6406-4cf0-865a-2e0fc72aa75f',
      redirectUri: config.redirectUrl,
      authority: 'https://login.microsoftonline.com/katvasoft.com'
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: false
    },
    system: {
      navigateFrameWait: 0,
    },
  });