import React, {useState,useContext} from "react";
import { useHistory } from "react-router-dom";
import PortfolioList from '../components/portfolio/PortfolioList';
import PortfolioForm from '../components/portfolio/portfolioform';
// react-bootstrap components
import { AuthContext } from '../authContext';

function Portfolios() {

  const [refreshVar, refreshTable] = useState({});
  const [selectedRow, rowSelected] = useState({});
  const ctx = useContext(AuthContext);
  const history = useHistory();
  if (ctx && ctx.token && ctx.token.accessToken) {
    
  } else {
    history.push('/');
  }
  const resetSelectedPortfolio = () => {

    const emptyVal = {
      'id' : null,
      'portfolioName': null,
      'description' : null,
      'portfolioHolder': null,
      'portfolioCode' : null
    };
    rowSelected(emptyVal);
    console.log('Reset selected row : ', selectedRow);
  }

  return (
    <div>
      <PortfolioForm refreshTable={refreshTable} selectedPortfolio={selectedRow} resetForm={resetSelectedPortfolio}></PortfolioForm>
      <PortfolioList needRefresh={refreshVar} onPortfolioSelected={rowSelected}></PortfolioList>
    </div>
  );
}

export default Portfolios;
