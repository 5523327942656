import React, { useState, useEffect, useContext } from "react";
import {
    Badge,
    Button,
    Card,
    Navbar,
    Nav,
    Table,
    Container,
    Row,
    Col,
} from "react-bootstrap";
import NotesListRow from './NotesListRow';

function NotesList({notes, removeNoteFunc, selectFunc}) {
    

    return (
    <>
        <Container fluid>
            <Row>
            <Col md="8">
                    <Card className="strpied-tabled-with-hover">

                        <Card.Header>
                            <Card.Title as="h4">Notes</Card.Title>
                            <p className="card-category">
                                Here is a list of your notes
                </p>
                        </Card.Header>
                        <Card.Body className="table-full-width table-responsive px-0">
                            <Table className="table-hover table-striped">
                                <thead>
                                    <tr>
                                        <th className="border-0">Name</th>
                                        <th className="border-0">Isin</th>
                                        <th className="border-0">Low price</th>
                                        <th className="border-0">High price</th>
                                        <th className="border-0"></th>
                                        <th className="border-0"></th>
                                        
                                    </tr>
                                </thead>
                                <tbody>
                                    { notes &&
                                        notes.map(item => (
                                            <NotesListRow key={item.id} remove={removeNoteFunc} note={item} selectNote={selectFunc}></NotesListRow>
                                            
                                        ))
                                    }
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>

                </Col>
            </Row>
        </Container>
    </>)
};

export default NotesList;