export function showNotification(message, type, notificationAlertRef) {

    let options = {};
    options = {
        place: 'tc',
        message: (
            <div>
                <div>
                    {message}
                </div>
            </div>
        ),
        type: type,
        icon: "nc-icon nc-bell-55",
        autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);

}