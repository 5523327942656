import React,  { useState, useEffect, useContext }  from "react";
import ChartistGraph from "react-chartist";
import DashBoardCard from '../components/DashBoardCard/DashBoardCard';
import SimpleLineChart from '../components/Charts/SimpleLineChart';
import {AuthContext} from '../authContext';
import {getRandomNumber} from '../components/utils';
import { config } from '../config';
import moment from 'moment';
import axios from 'axios';
import {
  Container,
  Row

} from "react-bootstrap";

function Dashboard() {

  const [portfolios, setPortfolios] = useState([]);
  const [chartData, setChartData] = useState([]);

  const [summary, setSummary] = useState([]);

  const ctx = useContext(AuthContext);

  let tokenStr;
  if(ctx.token) {
    tokenStr = ctx.token.idToken.rawIdToken;

  }
 
  useEffect(async () => {
   
    if(tokenStr) {
      const result = await axios(config.backendUrl + '/portfolio',{headers: {"Authorization" : `Bearer ${tokenStr}`}});
      setPortfolios(result.data);

      const summaryResult = await axios(config.backendUrl + '/portfolio/summary/calculate/all', {headers: {"Authorization" : `Bearer ${tokenStr}`}});
      
      setSummary({
          summaryProfit: Number(summaryResult.data.summaryProfit).toFixed(2),
          summaryValue: Number(summaryResult.data.summaryValue).toFixed(2)
      })
      
      if(result.data && result.data.length > 0) {
        const profitChartData = await Promise.all(result.data.map((async (portfolio) => {
          const chartDataUrl = `${config.backendUrl}/portfolio/summary/latest10/${portfolio.id}`;
          const chartDataResult = await axios(chartDataUrl,{headers: {"Authorization" : `Bearer ${tokenStr}`}});
          const chartData = { 'title' : portfolio.portfolioName, 'data': chartDataResult.data.map(dataResult => { return { 'profit' : dataResult.portfolioProfitOrLoss, 
          'date' : moment(dataResult.portfolioValueDate).format('DD.MM.YYYY')} }).reverse()}
          
          return chartData
        })))
        console.log('Profit chart data : ', profitChartData)
        setChartData(profitChartData);
      }
     
    }
    

},[tokenStr])

  return (
    <>
      <Container fluid>
        <h2>Portfolios in short </h2>
        <Row>
            <h4>All portfolios value : {summary.summaryValue}</h4>

        </Row>
          <Row>
              <h4>All portfolios profit : {summary.summaryProfit}</h4>
          </Row>
          <Row>
            <br/>
          </Row>
        <Row>
        {portfolios && portfolios.map(item => 
        (<DashBoardCard key={item.id} portfolioId={item.id} portfolioName={item.portfolioName}></DashBoardCard>)
        )}
        </Row>
        <Row>
            <h4>Portfolio profits chart:</h4>
          </Row>
        <Row>
         
          
          {chartData && chartData.map(chartData => <SimpleLineChart key={chartData.title} title={chartData.title} data={chartData.data} yDataKey="profit" xDataKey="date"></SimpleLineChart>)}
        </Row>
      </Container>
    </>
  );
}

export default Dashboard;
