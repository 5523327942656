import React, { useState, useEffect, useContext } from "react";
import {config} from '../../config';
import axios from 'axios';
    import {
        Badge,
        Button,
        Card,
        Navbar,
        Nav,
        Table,
        Container,
        Row,
        Col,
    } from "react-bootstrap";
import SecurityTradeRow from './SecurityTradeRow';
import {AuthContext} from '../../authContext';
import ConfirmationModal from '../Common/ConfirmationModal';

function SecurityTradeList ({queryObj,onTradeSelected, onTradeDelete}) {

    const [data, setData] = useState([]);
    const [modalShow, showModal] = useState(false);
    const [tradeToDelete, setTradeToDelete] = useState();
    const ctx = useContext(AuthContext);
    const tokenStr = ctx.token.idToken.rawIdToken;

    const rowSelected = (row) => {
        onTradeSelected(row);
    }

    const onDeleteCancel = () => {

        showModal(false);
    }

    const onDeleteConfirmed = () => {

        showModal(false);
        onTradeDelete(tradeToDelete);

    }

    const showDeleteModal = (trade) => {

        setTradeToDelete(trade);
        showModal(true);

    }

    let fetchData = queryObj;

    useEffect(async () => {

        const searchObj = {
            from: fetchData.fromDate,
            to: fetchData.toDate
        }

        const result = await axios({
            method: 'POST',
            url: config.backendUrl +'/stock/trade/portfolio/'+fetchData.portFolioId,
            data: searchObj,
            headers: {"Authorization" : `Bearer ${tokenStr}`}
        });
        console.log('Got data : ',result);
        setData(result.data);
    }, [fetchData])


    return (

        <Container fluid>
            <Row>
                <Col md="10">
                <Card className="strpied-tabled-with-hover">
                <Card.Header>
                            <Card.Title as="h4">Security trades</Card.Title>
                            <p className="card-category">
                                Here is a list of your security trades
                </p>
                        </Card.Header>
                        <Card.Body className="table-full-width table-responsive px-0">
                        <Table className="table-hover table-striped">
                                <thead>
                                    <tr>
                                        <th className="border-0">Security name</th>
                                        <th className="border-0">Number of securities bought or sold</th>
                                        <th className="border-0">Transaction date</th>
                                        <th className="border-0">Security price</th>
                                        <th className="border-0">Trade type</th>
                                        <th className="border-0"></th>
                                        
                                    </tr>
                                </thead>
                                <tbody>
                                    { data &&
                                        data.map(item => (
                                            <SecurityTradeRow key={item.id} trade={item} onRowSelected={rowSelected} onRowDelete={showDeleteModal}></SecurityTradeRow>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        </Card.Body>
                        </Card>
                </Col>
            </Row>
            <ConfirmationModal modalShow={modalShow} confirmationTitle="Are you sure ?" body="Do you want delete the trade ?" cancel={onDeleteCancel} confirm={onDeleteConfirmed}></ConfirmationModal>                              
        </Container>

    )


}

export default SecurityTradeList;