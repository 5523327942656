import React, { useState, useEffect, useContext } from "react";
import PortfolioAlertListRow from './PortfolioAlertListRow';
import {
    Card,
    Table,
    Container,
    Row,
    Col,
} from "react-bootstrap";

function PortfolioAlertList({ portfolioAlerts, onEdit, onDelete }) {

    console.log('portfolio alerts : ', portfolioAlerts);

    return (
        <Container fluid>
            <Row>
                <Col md="8">
                    <Card className="strpied-tabled-with-hover">
                        <Card.Header>
                            <Card.Title as="h4">Portfolio alerts</Card.Title>
                            <p className="card-category">
                                Here is a list of your portfolio alerts
                            </p>
                        </Card.Header>
                        <Card.Body className="table-full-width table-responsive px-0">
                            <Table className="table-hover table-striped">
                                <thead>
                                    <tr>
                                        <th className="border-0">Portfolio name</th>
                                        <th className="border-0">Minimum profit</th>
                                        <th className="border-0">Minimum value</th>



                                    </tr>
                                </thead>
                                <tbody>
                                    {portfolioAlerts &&
                                        portfolioAlerts.map(item => (
                                            <PortfolioAlertListRow key={item.id} onEdit={onEdit} portfolioAlert={item} onDelete={onDelete}></PortfolioAlertListRow>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default PortfolioAlertList;